import { INVOICE_LOAD, INVOICE_UNLOAD } from '../constants/actionTypes';

export default (state = [], action) => {
    switch (action.type) {
        case INVOICE_LOAD:
            return {
                ...state,
                invoices: action.payload
            };
        case INVOICE_UNLOAD:
            return [];
        default:
            return state;
    }
};
