import React from 'react';
import {connect} from "react-redux";


const mapStateToProps = state => ({...state.common})

const AuthUser = props => {
    const user = props.currentUser

    return (
        <div></div>
    )
}

class Admin extends React.Component {

    constructor() {
        super();
    }

    render() {
        return (
            <AuthUser currentUser={this.props.currentUser}/>
        )
    }
}


export default connect(mapStateToProps)(Admin);
