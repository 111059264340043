export const dateToString = (rawDate) => {
  const date = rawDate.toJSON().split('T')[0];
  const time = rawDate.toJSON().split('T')[1];

  const year = date.split('-')[0];
  const month = date.split('-')[1];
  const day = date.split('-')[2];
  
  const hoursNum = Number(time.split(':')[0]);
  const hours = `${hoursNum < 18 ? hoursNum + 6 : hoursNum - 18}`.padStart(2, '0');
  const minutes = time.split(':')[1];

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}
