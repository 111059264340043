import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  SETTINGS_SAVED,
  LOGIN,
  REGISTER,
  DELETE_ARTICLE,
  EDITOR_PAGE_UNLOADED,
  HOME_PAGE_UNLOADED,
  PROFILE_PAGE_UNLOADED,
  PROFILE_FAVORITES_PAGE_UNLOADED,
  SETTINGS_PAGE_UNLOADED,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED
} from '../constants/actionTypes';

const defaultState = {
  appName: 'SLQ',
  token: null,
  viewChangeCounter: 0
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      let currentUser;
      if(action.payload &&
          action.payload.hasOwnProperty('error') &&
          action.payload.error === 'Unauthorized') {
        currentUser = null;
      } else {
        currentUser =  action.payload;
      }
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: currentUser
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    case LOGOUT:
      return { ...state, redirectTo: `/`, token: null, currentUser: null };
    case SETTINGS_SAVED:
      return {
        ...state,
        redirectTo: action.error ? null : `/`,
        currentUser: action.error ? null : action.payload
      };
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        redirectTo: action.error ? null : `/admin`,
        token: action.error ? null : action.payload.token,
        currentUser: action.error ? null : action.payload
      };
    case DELETE_ARTICLE:
      return { ...state, redirectTo: '/' };
    case EDITOR_PAGE_UNLOADED:
    case HOME_PAGE_UNLOADED:
    case PROFILE_PAGE_UNLOADED:
    case PROFILE_FAVORITES_PAGE_UNLOADED:
    case SETTINGS_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    default:
      return state;
  }
};
