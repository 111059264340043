import auth from './reducers/auth';
import { combineReducers } from 'redux';
import common from './reducers/common';
import home from './reducers/home';
import { routerReducer } from 'react-router-redux';
import postalService from "./reducers/postalService";
import postalRoute from "./reducers/postalRoute";
import order from "./reducers/order";
import orderStatus from "./reducers/orderStatus";
import productCategory from "./reducers/productCategory";
import offer from "./reducers/offer";
import orderParcel from "./reducers/orderParcel";
import taskQueue from "./reducers/taskQueue";
import error from "./reducers/error";
import searchState from "./reducers/searchState";
import report from "./reducers/report";
import results from './reducers/results'
import invoice from "./reducers/invoice";
import orgs from "./reducers/orgs";
import slqUser from './reducers/slqUser'
import calcTariff from './reducers/calcTariff'

export default combineReducers({
  auth,
  common,
  home,
  postalService,
  postalRoute,
  order,
  orderStatus,
  productCategory,
  offer,
  orderParcel,
  taskQueue,
  router: routerReducer,
  error,
  searchState,
  report,
  invoice,
  results,
  orgs,
  slqUser,
  calcTariff
});
