import {Menubar} from "primereact/menubar";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";
import {history} from "../../store";
import {Menu} from "primereact/menu";
import {useRef} from "react";


const LoggedView = props => {
    const menu = useRef(null);

    let items = [
        {
            label: 'Аккаунт',
            items: [
                {label: 'Настройки', icon: 'pi pi-fw pi-cog', command: () => history.push(`/settings`)},
                {label: 'Выйти', icon: 'pi pi-fw pi-power-off', command:() =>  props.logoutHandler()}
            ]
        }
    ]

    if (!props.currentUser) {
        return (
            <ul className="nav navbar-nav pull-xs-right">

                <li className="nav-item">
                    <Button label="Войти" onClick={e => history.push(`/login`)} />
                </li>
            </ul>
        );
    } else {
        return (
            <ul className="nav navbar-nav pull-xs-right">
                <li className="nav-item">
                    <Menu model={items} popup ref={menu} />
                    <Button label={`${props.currentUser.lastName} ${props.currentUser.firstName}`}
                            className="p-button-text p-button-plain"
                            onClick={(event) => menu.current.toggle(event)}/>
                </li>
            </ul>
        );
    }
};

const Navbar = props => {

    const authorities = (props.currentUser || {authorities: []}).authorities
    const items = (authorities||[]).some(a => a.authority ===  "ADMIN") ? [

        {
            label: 'Aдминка',
            icon: 'pi pi-fw pi-user',
            items: [{ label: 'Заказы', command:()=> { history.push(`/orders`) }},
                    { label: 'Поставщики', command:()=> { history.push(`/postal_services`) }},
                    { label: 'Категория товаров', command:()=> { history.push(`/product_categories`) }},
                    { label: 'Направления', command:()=> { history.push(`/postal_routes`) }},
                    { label: 'Калькулятор', command:()=> { history.push(`/calculator`) }}]
        },
        {
            label: 'SLQ Портал',
            icon: 'pi pi-fw pi-link',
            items: [{ label: 'Пользователи', command: () => {history.push(`/users`)}

                    }]

        },
        {
            label: 'Тарифы',
            icon: 'pi pi-fw pi-chart-bar',
            items: [{ label: 'Администрирование тарифов', command: () => {history.push(`/calc-tariffs`)}

                    }]

        }
    ] : []

    const start = <Link to="/"><h2>{ props.appName }</h2></Link>
    const end = <LoggedView currentUser={props.currentUser} logoutHandler={props.logoutHandler}/>;

    return (
        <div>
            <div className="card">
                <Menubar model={items} start={start} end={end} />
            </div>
        </div>
    );
}
export default Navbar
