import React from "react";
import { mileTypesTranslate } from "../CalcTariffs/mockData/mileTypesTranslate";
import { tariffTypesTranslate } from "../CalcTariffs/mockData/tariffTypesTranslate";

export const ZoneInfoTable = ({
  psZoneInfo
}) => {
  return (
    <table style={{width: "100%", marginBottom: "30px", textAlign: "center", color: "#222", borderCollapse: "collapse"}}>
      <thead>
        <tr style={{backgroundColor: "#f8f9fa"}}>
          <th style={{padding: "5px", border: "1px solid #ddd"}}>Грузоперевозчик</th>
          <th style={{border: "1px solid #ddd"}}>Услуга</th>
          <th style={{border: "1px solid #ddd"}}>Тип тарифа</th>
          <th style={{border: "1px solid #ddd"}}>Тип мили</th>
          <th style={{border: "1px solid #ddd"}}>Наименование зоны</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{backgroundColor: "#fff"}}>
          <td style={{padding: "5px", border: "1px solid #ddd"}}>{psZoneInfo && psZoneInfo.postalServiceDto.nameRu}</td>
          <td style={{border: "1px solid #ddd"}}>{psZoneInfo && psZoneInfo.psTariffDto.nameRu}</td>
          <td style={{border: "1px solid #ddd"}}>{psZoneInfo && tariffTypesTranslate[psZoneInfo.tariffType]}</td>
          <td style={{border: "1px solid #ddd"}}>{psZoneInfo && mileTypesTranslate[psZoneInfo.mileType]}</td>
          <td style={{border: "1px solid #ddd"}}>{psZoneInfo && psZoneInfo.zoneName}</td>
        </tr>
      </tbody>
    </table>
  )
}