import React, {useEffect, useRef, useState} from "react"
import Select from "react-select"
import makeAnimated from "react-select/animated"

const animatedComponents = makeAnimated();

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

const SearchSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const selectInputRef = useRef();

  useEffect(() => {
      if(!props.state[props.optionValue]) {
          selectInputRef.current.select.clearValue();
      } else if(props.state[props.optionValue]) {
          selectInputRef.current.select.setValue(selectInputRef.current.select.getValue()[0]);
      }
  }, [props.state[props.optionValue]]);

  const customFilterOption = (option, rawInput) => {
      return option
  };
  const debounceInputChange = debounce((inputValue) =>
    inputValue.length > 2
    ? (setLoading(true), props.onChange(inputValue))
    : (setLoading(false)),
    500);

  return (
    <Select
      name={props.name}
      ref={selectInputRef}
      options={props.options||[]}
      onInputChange={(inputValue) => debounceInputChange(inputValue)}
      onChange={(e) => {
        setLoading(false);
        e ? props.setState(e) : props.setState({ [props.optionValue]: '' })
      }}
      isLoading={loading}
      getOptionValue={(e) => e[props.optionValue]}
      getOptionLabel={(e) => e[props.optionText]}
      placeholder={props.placeholder}
      loadingMessage={() => null}
      noOptionsMessage={() => null}
      isClearable={(props.name === 'parcelPickup' || props.name === 'parcelDelivery') ? false : true}
      isSearchable
      components={animatedComponents}
      filterOption={customFilterOption}
      styles={{ control: styles => ({ ...styles, 
        borderColor: props.highlightRequired ? "red" : "#ccc" }), }}
    />
  )
}

export default SearchSelect;
