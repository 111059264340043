import 'primeflex/primeflex.css';
import {InputText} from 'primereact/inputtext'
import {Calendar} from "primereact/calendar";
import {addLocale} from 'primereact/api';
import { connect } from 'react-redux';
import { CHANGE_REQUIRED_FIELD } from '../../constants/actionTypes';
import React from "react";

addLocale('ru', {
  firstDayOfWeek: 1,
  dayNames: ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
  dayNamesShort: [ "Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
  dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сетябрь", "Октябрь", "Ноябрь", "Декабрь"],
  monthNamesShort: ["Янв", "Фев", "Мрт", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Нбр", "Дек"],
  today: 'Сегодня',
  clear: 'Отчистить',
  dateFormat: 'dd.mm.yy',
  weekHeader: 'Sm'
});

const mapStateToProps = state => ({
  ...state.results
})
const mapDispatchToProps = dispatch => ({
  changeRequiredField: (name, value) => {
    dispatch({type: CHANGE_REQUIRED_FIELD, name, value})
  }
})

const MainKeys = (props) => {
  return (
    <>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label htmlFor='from' className="p-col-12 p-md-5">Откуда</label>
          <div className="p-col-12 p-md-7">
              <InputText name="from" id='from' type="text" value={props.from.nameRu || ''} style={{pointerEvents: 'none'}} />
          </div>
        </div>
      </div>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label htmlFor='to' className="p-col-12 p-md-5">Куда</label>
          <div className="p-col-12 p-md-7">
              <InputText name="to" id='to' type="text" value={props.to.nameRu || ''} style={{pointerEvents: 'none'}} />
          </div>
        </div>
      </div>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label htmlFor='cost' className="p-col-12 p-md-5">Стоимость товара</label>
          <div className="p-col-12 p-md-7">
              <InputText name="cargoValue" id='cargoValue' type="text" value={props.cargoValue || ''} style={{pointerEvents: 'none'}} />
          </div>
        </div>
      </div>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label htmlFor='seats' className="p-col-12 p-md-5">Количество грузомест/посылок в отправке</label>
          <div className="p-col-12 p-md-7">
              <InputText name="seats" id='seats' type="text" value={props.numPack || ''} style={{pointerEvents: 'none'}} />
          </div>
        </div>
      </div>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label htmlFor='psName' className="p-col-12 p-md-5">Наименование грузоперевозчика</label>
          <div className="p-col-12 p-md-7">
              <InputText name="psName" id='psName' type="text" value={props.radio.psName} style={{pointerEvents: 'none'}} />
          </div>
        </div>
      </div>
     <div className="p-fluid">
        <div className="p-field p-grid">
            <label htmlFor='serviceType' className="p-col-12 p-md-5">Вид услги</label>
            <div className="p-col-12 p-md-7">
                <InputText name="serviceType" id='serviceType' type="text" value={props.radio.serviceType} style={{pointerEvents: 'none'}} />
            </div>
        </div>
      </div>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label htmlFor='paidWeight' className="p-col-12 p-md-5">Платный вес</label>
          <div className="p-col-12 p-md-7">
              <InputText name="paidWeight" id='paidWeight' type="text" value={props.paidWeight || ''} style={{pointerEvents: 'none'}} />
          </div>
        </div>
      </div>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label htmlFor='weight' className="p-col-12 p-md-5">Физический вес</label>
          <div className="p-col-12 p-md-7">
              <InputText name="weight" id='weight' type="text" value={props.weight || ''} style={{pointerEvents: 'none'}} />
          </div>
        </div>
      </div>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label htmlFor='productName' className="p-col-12 p-md-5">Наименование товара</label>
          <div className="p-col-12 p-md-7">
              <InputText name="productName" id='productName' type="text" value={props.productName || ''} style={{pointerEvents: 'none'}} />
          </div>
        </div>
      </div>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label htmlFor='productCategory' className="p-col-12 p-md-5">Категория товара</label>
          <div className="p-col-12 p-md-7">
              <InputText name="productCategory" id='productCategory' type="text" value={props.productCategory.name_ru || ''} style={{pointerEvents: 'none'}} />
          </div>
        </div>
      </div>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <span style={{color: 'red', marginTop: '8px'}}>*</span>
          <label htmlFor='pickupDate' className="p-col-12 p-md-5">Контрольная дата забора</label>
          <div className="p-col-12 p-md-6">
            <Calendar name='pickupDate' id="pickupDate" value={props.requiredFields.pickupDate || ''}
                      onChange={(e) => props.changeRequiredField(e.target.name, e.target.value)}
                      selectionMode='range' readOnlyInput locale="ru" placeholder="обязательное поле" />
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MainKeys)
