import React, {useState, useRef, useMemo} from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { EditWeightListDialog } from "./EditWeightListDialog";

const CalcRules = ({
  data,
  onSubmit,
  calcTariff,
  checkSubmit,
  title1,
  title2
}) => {
  const tableRef = useRef();
  const [loading, setLoading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  const onEditModalShow = () => setIsModalShown(true);
  const onEditModalHide = () => setIsModalShown(false);

  const [listData, setListData] = useState(data);

  const sortedListData = useMemo(() => {
    return listData ? [...listData].sort((a, b) => (+a?.until) - (+b?.until)) : [];
  }, [listData]);

  React.useEffect(() => {
    setListData(data);
  }, [calcTariff]);

  const onWeightListEditSubmit = (list, setList) => {
    checkSubmit(list);

    setLoading(true);
    onSubmit({
      list, 
      setList, 
      setLoading, 
      onEditModalHide,
      sortedListData
    });
  }

  const updateListData = (setListData, idx, name, value) => {
    setListData(prevArray => {
      const newArray = [...prevArray];

      if (['', null, undefined].includes(value)) { value = '0' }
      else { value = value.toString() }

      // next.step or next.price
      if (name.includes('.')) {
        const nested = name.split('.');

        if (newArray[idx][nested[0]]) {
          newArray[idx][nested[0]][nested[1]] = +value;
        }
        else {
          newArray[idx][nested[0]] = {
            price: 0,
            step: 0,
            [nested[1]]: +value
          }
        }
      }
      else { newArray[idx][name] = +value }

      return newArray
    })
  };

  const removeData = (setListData, rowIndex) => {
    setListData(prevArray => {
      const newArray = prevArray.filter((item, idx) => idx !== rowIndex && item);
      return newArray;
    })
  }

  const addNewData = (setListData) => {
    setListData(prevArray => {
      const newArray = prevArray.length > 0 ? [...prevArray] : [];
      newArray.push({
        "until": 0,
        "price": 0,
        "next": {"step": 0, "price": 0}
      });
      return newArray
    })
  };

  const handleReject = (changeListData) => {
    changeListData(sortedListData); 
    onEditModalHide();
  };

  function renderEditorFooter(formName, changeListData) {
    return (
      <div>
        <Button label="Отменить" icon="pi pi-times" onClick={() => handleReject(changeListData)} className="p-button-text" />
        <Button label="Добавить ещё..." icon="pi pi-plus" onClick={() => addNewData(changeListData)} className="p-button-text" />
        <Button label="Сохранить" icon="pi pi-check" type="submit" form={formName} autoFocus loading={loading} />
      </div>
    );
  }

  return (
    <>
      <EditWeightListDialog
        showEditDialog={isModalShown}
        onEditHide={onEditModalHide}
        onEditSubmit={onWeightListEditSubmit}
        renderEditFooter={renderEditorFooter}
        updateListData={updateListData}
        removeData={removeData}
        formName={"weightListEditForm"}
        title={title2}
        listData={sortedListData}
      />

      <div className="p-d-block" style={{flex: .5}}>
        <div className="p-d-flex p-jc-between p-ai-center">
          <h2>{title1}</h2>
          <Button className="p-d-block" label="Редактировать" onClick={onEditModalShow} icon="pi pi-pencil" />
        </div>

        <DataTable ref={tableRef} value={listData}
                    className="p-datatable-customers" dataKey="id" rowHover
                    emptyMessage="Данные не найдены" scrollable scrollHeight="270px">
            <Column columnKey="until" field="until" header="Начальный вес, кг" />
            <Column columnKey="price" field="price" header="Цена за начальный вес, тг" />
            <Column columnKey="next.step" field="next.step" header="Шаг" />
            <Column columnKey="next.price" field="next.price" header="Цена за шаг, тг" />
        </DataTable>
      </div>
    </>
  );
};

export default CalcRules;