import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import agent from "../../agent";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from "primereact/checkbox";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {dateToString} from "../../utils/dateToString";
import {InputText} from "primereact/inputtext";

const Invoice = (props) => {

    const toast = useRef(null);

    const [expandedRows, setExpandedRows] = useState([]);
    const [showConfirmText, setShowConfirmText] = useState(false)
    const [confirmText, setConfirmText] = useState('');
    const [confirmLabel, setConfirmLabel] = useState('');
    const [loading, setLoading] = useState(false)
    const [showDialog, setShowDialog] = useState(false);
    const [lines, setLines] = useState([{ itemId: 0, quantity: 1, sum: null }])
    const [invoiceId, setInvoiceId] = useState(null);
    const [refund, setRefund] = useState(false);
    const [dueDate, setDueDate] = useState(null);
    const [items, setItems] = useState([]);
    const [row, setRow] = useState(null);

    const onRowExpand = (event) => {
        toast.current.show({severity: 'info', summary: `Позиции чеко по счету раскрыты №  ${event.data.id}`, detail: event.data.name, life: 3000});
    }

    useEffect(() => {
        agent.ApiItems.get().then(response => {
            setItems(response);
        });
    }, [])

    const onRowCollapse = (event) => {
        toast.current.show({severity: 'success', summary: `Позиции чеко по счету скрыты №  ${event.data.id}`, detail: event.data.name, life: 3000});
    }

    const isRefund = (rowData) => {
        return <span>{rowData.refund ? 'Да' : 'Нет'}</span>;
    }


    const expandAll = () => {
        let expandedRows = {};
         props.invoices.forEach(p => expandedRows[`${p.id}`] = true);

        setExpandedRows(expandedRows
        , () => {
            toast.current.show({severity: 'success', summary: 'All Rows Expanded', life: 3000});
        });
    }

    const collapseAll = () => {
        setExpandedRows([]
            , () => {
            toast.current.show({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
        });
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h4>Позиции чека</h4>
                <DataTable value={data.lines}>
                    <Column field="name" header="Наименование" sortable/>
                    <Column field="quantity" header="Количесто" sortable/>
                    <Column field="articul" header="Артикул" sortable/>
                    <Column field="sum" header="Сумма" sortable/>
                </DataTable>
            </div>
        );
    }

    const unblockUI = () => {
        agent.Lock.unlock(`invoice#${props.track}`)
            .then((response) => {
                toast.current.show({severity: 'success', summary: `Вкладка разблокирована`})
                props.setActiveIndex(0);
            }).catch((error) =>  {
            const data = JSON.parse(error.response.text)
            toast.current.show({severity: 'error', summary: `Ошибка разблокировки вкладки ${data.message}`});0
        })
    }

    const addNewInvoice = () => {
        resetDialog()
        if(row) {
            if(row.refund) {
                toast.current.show({severity: 'error', summary: `Нельзя выбирать счет возврата`});
                return;
            }
            const newLines = [];
            (row.lines||[]).forEach((line, index) => {
                newLines[index] = { itemId: line.itemId, quantity: line.quantity, sum: line.sum }
            })
            setLines(newLines);
            setRefund(true);
            setDueDate(new Date());
            setInvoiceId(row.id)
        }

        setShowDialog(true)
    }

    const header = (
        <div className="table-header-container">
            <Button label="Разблокировать вкладку" className="p-mr-2 p-button-danger" onClick={(e) => unblockUI()}/>
            <Button label="Добавить" className="p-mr-2" onClick={(e) => {
                addNewInvoice();
            }}/>
            <Button icon="pi pi-plus" label="Раскрыть все" onClick={expandAll} className="p-mr-2" />
            <Button icon="pi pi-minus" label="Скрыть все" onClick={collapseAll} className="p-mr-2" />
            <Button label="Снять выбор" onClick={(e) => setRow(null)} />
        </div>
    );


    const resetDialog = () => {
        setShowConfirmText(false)
        setLoading(false)
        setLines([{ itemId: 0, quantity: 1, sum: null }])
        setRefund(false);
        setDueDate(null);
        setConfirmText('');
        setConfirmLabel('');
        setInvoiceId(null)
    }

    const ok = (e) => {
        // const formatDate = dateFormat(dueDate);


        if(!showConfirmText) {
            const reducer = (previousValue, currentValue) => previousValue + currentValue;
            const total = lines.map(line => line.sum).reduce(reducer);
            setShowConfirmText(true);
            setConfirmLabel(refund ? `Возвратить деньги на кошелек в размере ${total} тенге`.toUpperCase() : `Выставить счет в размере ${total} тенге`.toUpperCase() )
            return;
        }

        if(confirmText !== confirmLabel) {
            toast.current.show({severity: 'error', summary: `Неверно заполнен текст подтверждения «${confirmLabel}»`, life: 5000});
            return;
        }

        const invoiceLine = lines.map((line, index) => {
            return {
                itemId: line.itemId,
                quantity: line.quantity,
                sum: line.sum,
            }
        });
        const body = {
            refund,
            dueDate: dateToString(dueDate),
            lines: invoiceLine
        }
        setLoading(true)
        agent.Invoices.save(props.track, body)
            .then((response) => {
                props.onInvoiceTab(new Promise((resolve, reject) => {
                    resolve(response);
                }));
                setShowDialog(false);
            }).catch((error) => {
                console.log(error.response)
                const response = JSON.parse(error.response.text);
                toast.current.show({severity: 'error', summary: `${response.message}`});
            }).finally(() =>
                setLoading(false)
            );

    }

    const onCreateHide = () => {
        setShowDialog(false);
    }

    const renderCreateFooter = () => {
        return (
            <div>
                <Button label="Нет" icon="pi pi-times" onClick={() => onCreateHide()} className="p-button-text" />
                <Button form="createTaskForm" label="Да"  loading={loading} onClick={() => ok()} icon="pi pi-check" autoFocus />
            </div>
        );
    }

    const addLine = (e) => {
        e.preventDefault()
        setLines([...lines, { itemId: 0, quantity: 1, sum: null }])
    }

    const removeLine = (e, index) => {
        e.preventDefault()
        const newLines = [...lines]
        newLines.splice(index, 1)
        setLines(newLines);
    }
    const clearConf = () => {
        setConfirmLabel('');
        setConfirmText('');
        setShowConfirmText(false);
    }

    return (
        <div className="datatable-rowexpansion-demo">
            <Dialog header="Создание" visible={showDialog} style={{ width: '50vw' }} footer={renderCreateFooter()} onHide={() => onCreateHide()}>
                <form id="createInvoiceForm">
                    <div className="p-fluid">
                        <div className="p-mb-2 p-mr-2">
                            <label htmlFor="refund" className="p-mb-2 p-mr-2">Возврат денег на кошелек?</label>
                            <Checkbox id="refund" onChange={e => {
                                setRefund(e.checked);
                                clearConf();
                            }} checked={refund} disabled={true}/>
                        </div>
                        <div className="p-field p-col-9 p-md-6">
                            <label htmlFor="dueTime" className="p-pb-2">До числа</label>
                            <Calendar id="dueTime" showTime hourFormat="24" dateFormat="dd.mm.yy" value={dueDate} onChange={(e) => {
                                setDueDate(e.value);
                                clearConf();
                            }}/>
                        </div>
                    </div>
                    <div>
                        <Button icon="pi pi-plus" disabled={refund} className="p-button-rounded p-button-text" onClick={(e) =>  {
                            addLine(e);
                            clearConf();
                        }}/>
                    </div>
                    { lines.map((x, i) => {
                        return (
                            <div className="p-fluid p-formgrid p-grid" key={i}>
                                <div className="p-field p-col-6 p-md-4">
                                    <label htmlFor="firstname6" className="p-pb-2">Позиция в чеке</label>
                                    <Dropdown inputId="firstname6" options={items}
                                              key={`${i}-items`}
                                              value={x.itemId}
                                              onChange={(e) => {
                                                  x.itemId = e.target.value
                                                  setLines([...lines])
                                                  clearConf()
                                              }}
                                              disabled={refund}
                                              optionLabel="nameRu" optionValue="id"/>
                                </div>
                                <div className="p-field p-col-6 p-md-3">
                                    <label htmlFor="firstname6" className="p-pb-2">Количество</label>
                                    <InputNumber value={x.quantity} onValueChange={(e) => {
                                        x.quantity = e.value;
                                        clearConf();
                                    }} disabled={refund}/>
                                </div>
                                <div className="p-field p-col-6 p-md-3">
                                    <label htmlFor="firstname6" className="p-pb-2">Сумма</label>
                                    <InputNumber value={x.sum} onValueChange={(e) => {
                                        x.sum = e.value;
                                        clearConf();
                                    }}/>
                                </div>
                                <div className="p-field">
                                    <Button icon="pi pi-minus" className="p-button-rounded p-button-text" onClick={(e) => {
                                        removeLine(e, i);
                                        clearConf();
                                    }} disabled={refund}/>
                                </div>
                            </div>
                        )
                    })}
                    { showConfirmText &&
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="confText" className="p-pb-2" style={{color: 'red'}}>{confirmLabel}</label>
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="confText" className="p-pb-2">Введите текст сверху</label>
                            <InputText id="confText" value={confirmText} onPaste={(e)=>{
                                e.preventDefault()
                                return false;
                            }} onCopy={(e)=>{
                                e.preventDefault()
                                return false;
                            }} onChange={(e) => setConfirmText(e.target.value)}/>
                        </div>
                    </div>
                    }
                </form>
            </Dialog>

            <Toast ref={toast} />

            <div className="card">
                <DataTable value={props.invoices} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                           onRowExpand={onRowExpand} onRowCollapse={onRowCollapse}
                           selection={row} onSelectionChange={e => setRow(e.value)}
                           rowExpansionTemplate={rowExpansionTemplate} dataKey="id" header={header}>
                    <Column expander style={{ width: '3em' }} />
                    <Column selectionMode="single" headerStyle={{width: '3em'}}/>
                    <Column field="id" header="№ счета" sortable />
                    <Column header="Возврат" body={isRefund} />
                    <Column field="dueDate" header="До числа" sortable/>
                    <Column field="status" header="Статус" sortable/>
                </DataTable>
            </div>
        </div>
    );
}

export default Invoice;
