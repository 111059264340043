import React from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { mockPostalServices } from "./mockData/mockPostalServices";
import { mockPostalTariffs } from "./mockData/mockPostalTariffs";
import { connect } from "react-redux";
import { CALC_TARIFF_POSTAL_SERVICES_LOAD, CALC_TARIFF_POSTAL_SERVICES_UNLOAD, 
  CALC_TARIFF_USLUGA_PS_LOAD, 
  CALC_TARIFF_USLUGA_PS_UNLOAD} from "../../../constants/actionTypes";
import agent from "../../../agent";
import { tariffTypes } from "./mockData/tariffTypes";

const mapStateToProps = state => ({
  calcTariff: state.calcTariff
});

const mapDispatchToProps = dispatch => ({
  onLoad: (postalServicesPayload) => {
    dispatch({ type: CALC_TARIFF_POSTAL_SERVICES_LOAD, payload: postalServicesPayload })
  },
  onPostalServicePicked: (uslugaPsPayload) => {
    dispatch({ type: CALC_TARIFF_USLUGA_PS_LOAD, payload: uslugaPsPayload})
  },
  onUnload: () => {
    dispatch({ type: CALC_TARIFF_POSTAL_SERVICES_UNLOAD }),
    dispatch({ type: CALC_TARIFF_USLUGA_PS_UNLOAD })
  }
});

const NewTariffDialog = props => {
  const { 
    showCreateDialog, 
    onCreateHide, 
    onCreateSubmit, 
    renderCreateFooter,
    updateNewTariffData,

    newTariffData,
    setNewTariffData,

    // form data
    postalService,
    postalTariffId,
    tariffType,
    mileType,
    zoneName
  } = props;

  const reduxCalcTariff = props.calcTariff; // redux
  const reduxPostalServices = reduxCalcTariff.postalServices;
  const reduxUslugaPs = reduxCalcTariff.uslugaPs;

  const [postalServices, setPostalServices] = React.useState(reduxPostalServices);
  const [uslugaPs, setUslugaPs] = React.useState(reduxUslugaPs);

  const mileTypes = [
    {label: "первая", value: "FIRST"},
    {label: "магистраль", value: "MIDDLE"},
    {label: "последняя", value: "LAST"}
  ];

  const handlePostalServiceChange = (e) => {
    // here i am using setNewTariffData instead of updateNewTariffData because multiple fields are changing
    setNewTariffData({ ...newTariffData, 
      ['postalTariffId']: "", 
      ['postalService']: e.value 
    });
    props.onPostalServicePicked(agent.CalcTariff.getUslugaPs(e.value));
  }

  React.useEffect(() => {
    props.onLoad(agent.CalcTariff.getPostalServices());
    return () => { props.onUnload() };
  }, []);
  React.useEffect(() => {
    setPostalServices(reduxPostalServices);
    setUslugaPs(reduxUslugaPs)
  }, [reduxCalcTariff]);

  return (
    <Dialog header="Создание нового тарифа" visible={showCreateDialog} style={{ width: '50vw' }} 
            footer={renderCreateFooter()} onHide={onCreateHide}>
        <form id="createForm" onSubmit={onCreateSubmit}>
            <div className="card">
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-12">
                        <label htmlFor="postalService" className="p-pb-2">Грузоперевозчик</label>
                        <Dropdown name="postalService" optionLabel="nameRu" optionValue="id" 
                                  value={postalService} options={postalServices}
                                  onChange={handlePostalServiceChange} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-12">
                        <label htmlFor="postalTariffId" className="p-pb-2">Услуга грузоперевозчика</label>
                        <Dropdown name="postalTariffId" optionLabel="nameRu" optionValue="id" 
                                  value={postalTariffId} options={uslugaPs}
                                  onChange={(e) => { updateNewTariffData('postalTariffId', e.value) }} 
                                  disabled={!postalService} placeholder={!postalService ? 'Выберите грузоперевозчика' : ''} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-12">
                        <label htmlFor="tariffType" className="p-pb-2">Тип тарифа</label>
                        <Dropdown name="tariffType" optionLabel="label" optionValue="value" 
                                  value={tariffType} options={tariffTypes}
                                  onChange={(e) => { updateNewTariffData('tariffType', e.value) }} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-12">
                        <label htmlFor="mileType" className="p-pb-2">Тип мили</label>
                        <Dropdown name="mileType" optionLabel="label" optionValue="value" 
                                  value={mileType} options={mileTypes}
                                  onChange={(e) => { updateNewTariffData('mileType', e.value) }} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-12">
                        <label htmlFor="zoneName" className="p-pb-2">Наименование варианта расчета</label>
                        <InputText name="zoneName" value={zoneName} type="text"
                                   onChange={(e) => { updateNewTariffData('zoneName', e.target.value) }} />
                    </div>
                </div>
            </div>
        </form>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NewTariffDialog);
