import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export const RemoveKatoConfirmDialog = ({ removeConfirmBundle }) => {
  const { visible, setVisible, selected, setSelected, onRemoveSubmit } = removeConfirmBundle;

  const onHide = () => { setVisible(false); setSelected(null) };
  const onCancel = () => { onHide() };

  const renderFooter = () => {
    return (
      <div>
        <Button label="Отменить" icon="pi pi-times" type="reset" onClick={onCancel} className="p-button-text" />
        <Button label="Подтвердить" icon="pi pi-check" type="button" onClick={onRemoveSubmit} autoFocus />
      </div>
    );
  }

  return (
    <Dialog header="Удаление выбранного направления КАТО" visible={visible} style={{ width: '50vw' }} 
            footer={renderFooter()} onHide={onHide}
    >
      <h3 style={{marginTop: 0}}>Вы уверены, что хотите удалить выбранное направление КАТО?</h3>
      <ul style={{listStyle: "outside", fontSize: "20px"}}>
        <li style={{marginBottom: "10px", color: "#D32F2F"}}>
          {selected && `${selected.katoFrom.nameRu} (${selected.katoFrom.code}) - ${selected.katoTo.nameRu} (${selected.katoTo.code})`}
        </li>
      </ul>
    </Dialog>
  )
}