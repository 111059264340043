import {
  CALC_TARIFF_POSTAL_SERVICES_LOAD,
  CALC_TARIFF_POSTAL_SERVICES_UNLOAD,
  CALC_TARIFF_PS_ZONES_LOAD,
  CALC_TARIFF_PS_ZONES_UNLOAD,
  CALC_TARIFF_PS_ZONE_KATO_LIST_LOAD,
  CALC_TARIFF_PS_ZONE_KATO_LIST_UNLOAD,
  CALC_TARIFF_USLUGA_PS_LOAD,
  CALC_TARIFF_USLUGA_PS_UNLOAD,
  CALC_TARIFF_WEIGHT_LIST_LOAD,
  CALC_TARIFF_WEIGHT_LIST_UNLOAD,
  CALC_TARIFF_TAWAY_LIST_LOAD,
  CALC_DEDUCTION_TT_LIST_LOAD
} from "../constants/actionTypes";

const defaultState = {
  postalServices: [],
  uslugaPs: [],
  psZones: [],
  psZoneKatoList: [],
  weightList: [],
  terminalAwayList: null,
  deductionTTList: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CALC_TARIFF_TAWAY_LIST_LOAD:
      return {
        ...state,
        terminalAwayList: action.payload
      }
    case CALC_DEDUCTION_TT_LIST_LOAD:
      return {
        ...state,
        deductionTTList: action.payload
      }
    case CALC_TARIFF_WEIGHT_LIST_LOAD:
      return {
        ...state,
        weightList: action.payload
      }
    case CALC_TARIFF_WEIGHT_LIST_UNLOAD:
      return {
        ...state,
        weightList: defaultState.weightList
      }
    case CALC_TARIFF_POSTAL_SERVICES_LOAD:
      return {
        ...state,
        postalServices: action.payload
      }
    case CALC_TARIFF_POSTAL_SERVICES_UNLOAD:
      return {
        ...state,
        postalServices: defaultState.postalServices
      }

    case CALC_TARIFF_USLUGA_PS_LOAD:
      return {
        ...state,
        uslugaPs: (action.payload ?? []).filter(val => !['P118', 'P104'].includes(val.code))
      }
    case CALC_TARIFF_USLUGA_PS_UNLOAD:
      return {
        ...state,
        uslugaPs: defaultState.uslugaPs
      }

    case CALC_TARIFF_PS_ZONES_LOAD:
      return {
        ...state,
        psZones: action.payload
      }
    case CALC_TARIFF_PS_ZONES_UNLOAD:
      return {
        ...state,
        psZones: defaultState.psZones
      }

    case CALC_TARIFF_PS_ZONE_KATO_LIST_LOAD:
      return {
        ...state,
        psZoneKatoList: action.payload
      }
    case CALC_TARIFF_PS_ZONE_KATO_LIST_UNLOAD:
      return {
        ...state,
        psZoneKatoList: defaultState.psZoneKatoList
      }

    default:
      return state
  }
}
