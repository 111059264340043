import 'primeflex/primeflex.css';
import {InputText} from 'primereact/inputtext'
import React, { useEffect, useRef, useState } from 'react';
import styles from "./Calculator.module.css"
import {Button} from "primereact/button"
import MainKeys from './mainKeys';
import { Dropdown } from 'primereact/dropdown';
import { connect } from 'react-redux';
import { CLEAR_ALL_FIELDS, CHANGE_REQUIRED_FIELD, CHANGE_NOT_REQUIRED_FIELD } from '../../constants/actionTypes';
import agent from "../../agent";
import {Toast} from "primereact/toast";

const mapStateToProps = state => ({
  ...state.results
})
const mapDispatchToProps = dispatch => ({
  changeRequiredField: (name, value) => {
    dispatch({type: CHANGE_REQUIRED_FIELD, name, value})
  },
  changeNotRequiredField: (name, value) => {
    dispatch({type: CHANGE_NOT_REQUIRED_FIELD, name, value})
  },
  clearAllFields: () => {
    dispatch({type: CLEAR_ALL_FIELDS})
  }
})

const Form = (props) => {
  const [sendIdAddress, setSendIdAddress] = useState(30);
  const [receiveIdAddress, setReceiveIdAddress] = useState(30);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [fillForm, setFillForm] = useState(false);
  const [streetAbbrOptions, setStreetAbbrOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const reqArray = [
    'pickupDate',
    'sendLawName',
    'sendName',
    'sendSurname',
    'sendIinBin',
    'sendBuilding',
    'sendPhone',
    'sendStreet',
    'receiveLawName',
    'receiveName',
    'receiveSurname',
    'receiveIinBin',
    'receiveBuilding',
    'receivePhone',
    'receiveStreet'
  ];

  const reusableKeys = [
    {label: 'Наименование юридического лица', name: 'lawName'},
    {label: 'Фамилия', name: 'surname'},
    {label: 'Имя', name: 'name'},
    {label: 'Отчество', name: 'patronymic'},
    {label: 'ИИН или БИН', name: 'iinBin'},
    {label: 'Улица', name: 'street'},
    {label: 'Дом', name: 'building'},
    {label: 'Корпус', name: 'housing'},
    {label: 'Квартира', name: 'apartment'},
    {label: 'Офис', name: 'office'},
    {label: 'Строение', name: 'str'},
    {label: 'Владение', name: 'ownership'},
    {label: 'Павильон', name: 'pavilion'},
    {label: 'Телефон', name: 'phone'},
    {label: 'Email', name: 'email'}
  ];
  const requiredKeys = [
    {label: 'Наименование юридического лица', name: 'lawName'},
    {label: 'Имя', name: 'name'},
    {label: 'Фамилия', name: 'surname'},
    {label: 'ИИН или БИН', name: 'iinBin'},
    {label: 'Номер дома', name: 'building'},
    {label: 'Номер телефона', name: 'phone'},
    {label: 'Улица', name: 'street'},
  ];


  useEffect(() => {
    agent.StreetAbbr.all().then(response => setStreetAbbrOptions(response))
  }, []);

  const prepareKeys = (array, key) => {
    return array.map(obj => {
      return ({label: obj.label, name: key + obj.name.charAt(0).toUpperCase() + obj.name.slice(1)})
    });
  }

  const sendKeys = prepareKeys(reusableKeys, 'send');
  const receiveKeys = prepareKeys(reusableKeys, 'receive');
  const sendReqKeys = prepareKeys(requiredKeys, 'send');
  const receiveReqKeys = prepareKeys(requiredKeys, 'receive');

  const placeReq = (arr, name) => {
    const reqMatch = arr.filter(item => name === item.name);
    if (reqMatch.length > 0) {
      return 'обязательное поле'
    }
    return ''
  }

  const changeReq = (arr, name) => {
    const reqMatch = arr.filter(item => name === item.name);
    if (reqMatch.length > 0) {
      return (e) => props.changeRequiredField(e.target.name, e.target.value);
    }
    return (e) => props.changeNotRequiredField(e.target.name, e.target.value);
  }

  const valueReq = (arr, name) => {
    const reqMatch = arr.filter(item => name === item.name);
    if (reqMatch.length > 0) {
      return props.requiredFields[name];
    }
    return props.notRequiredFields[name];
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const reqArrRaw = Object.entries(props.requiredFields);
    let i = 0;
    const reqArrMod = reqArrRaw.filter(item => item[0] === reqArray[i++]);

    let count = 0;
    reqArrMod.forEach(item => item[1] === '' ? count = 0 :  count++)

    if(count !== reqArrRaw.length || !sendIdAddress || !receiveIdAddress) {
      setFillForm(true)
      return;
    }
    postData();
    setSubmitDisabled(false);
    setFillForm(false)
  }

  const showMessage = (severity, summary, detail) => {
    toast.current.show({severity: severity, summary: summary, detail: detail, sticky: true });
  }

  const postData = () => {
    setLoading(true);
    const form = document.getElementById('form');
    const formData = new FormData(form);
    const json = JSON.stringify(Object.fromEntries(formData.entries()));
    const data = JSON.parse(json);

    const carrier = {
      name: props.radio.serviceType,
      postal: props.radio.postal
    }
    const sender = {
      companyName: data.sendLawName,
      email: data.sendEmail,
      extraInfo: data.sendPavilion,
      firstName: data.sendName,
      flat: data.sendApartment,
      house: data.sendBuilding,
      houseKorpus: data.sendHousing,
      iinOrBin: data.sendIinBin,
      lastName: data.sendSurname,
      middleName: data.sendPatronymic,
      office: data.sendOffice,
      phone: data.sendPhone,
      str: data.sendStr,
      street: data.sendStreet,
      streetAbbr: sendIdAddress,
      vlad: data.sendOwnership
    }

    const receiver = {
      companyName: data.receiveLawName,
      email: data.receiveEmail,
      extraInfo: data.receivePavilion,
      firstName: data.receiveName,
      flat: data.receiveApartment,
      house: data.receiveBuilding,
      houseKorpus: data.receiveHousing,
      iinOrBin: data.receiveIinBin,
      lastName: data.receiveSurname,
      middleName: data.receivePatronymic,
      office: data.receiveOffice,
      phone: data.receivePhone,
      str: data.receiveStr,
      street: data.receiveStreet,
      streetAbbr: receiveIdAddress,
      vlad: data.receiveOwnership
    }
    const body = {
        requestId: props.requestId,
        sender: sender,
        receiver: receiver,
        carrier: carrier,
        params: {
          hasDelivery: true
        },
       datePickup: data.pickupDate,
       numPack: props.numPack,
       comment: props.productName
    }

    agent.Orders.create(body, props.orgId).then(response => {
      response.error ?
        showMessage('error', 'Ошибка', response.error_description) :
        showMessage('success', 'Запрос выполнен', `Создан новый заказ ${response.orderNum}`)

    }).finally(() => setLoading(false))
  }

  return (
    <div className={styles.box}>
      <Toast ref={toast}/>
      <h2 style={{textAlign: 'center'}}>Создание заказа</h2>

      <form id='form' onSubmit={handleSubmit}>
        <div className={styles.card}>
          <fieldset className={styles.fieldset}>
            <legend>Основные данные</legend>
            <MainKeys result={props.result}
                      from={props.from}
                      to={props.to}
                      cargoValue={props.cargoValue}
                      numPack={props.numPack}
                      weight={props.weight}
                      productName={props.productName}
                      productCategory={props.productCategory}
                      paidWeight={props.paidWeight}
                      radio={props.radio} />
          </fieldset>
        </div>

        <div className={styles.card}>
          <fieldset className={styles.fieldset}>
            <legend>Данные отправителя</legend>
            {renderFromArray(sendKeys, sendReqKeys, changeReq, valueReq, placeReq)}
            {extraRender('sendIdAddress', sendIdAddress, setSendIdAddress,'nameRu', 'id', streetAbbrOptions)}
          </fieldset>
        </div>

        <div className={styles.card}>
          <fieldset className={styles.fieldset}>
            <legend>Данные получателя</legend>
            {renderFromArray(receiveKeys, receiveReqKeys, changeReq, valueReq, placeReq)}
            {extraRender('receiveIdAddress', receiveIdAddress, setReceiveIdAddress,'nameRu', 'id', streetAbbrOptions)}
          </fieldset>
        </div>

        <div className={styles.createBtn}>
          {fillForm && <p style={{margin: '5px 0', color: 'red'}}>заполните обязательные поля *</p>}
          <Button disabled={submitDisabled} type='submit' label="Создать заказ на доставку" loading={loading}/>
        </div>
      </form>
    </div>
  )
}


const extraRender = (name, state, setState, optL, optV, opts) => {
  return (
      <div className="p-fluid">
        <div className="p-field p-grid">
          <span style={{color: 'red', marginTop: '8px'}}>*</span>
          <label htmlFor={name} className="p-col-12 p-md-5">Идентификатор типа адресного объекта</label>
          <div className="p-col-12 p-md-6">
            <Dropdown
                name={name}
                inputId={name}
                value={state}
                options={opts}
                onChange={(e) => setState(e.value)}
                optionLabel={optL || ''} optionValue={optV || ''}
            />
          </div>
        </div>
      </div>
  )
}

const RequiredSar = (props) => {
  const reqMatch = props.reqArr.filter(item => item.name === props.name);
  if(reqMatch.length > 0) {
    return (
        <span style={{color: 'red', marginTop: '8px'}}>*</span>
    );
  }
  return (<span/>);
}
const renderFromArray = (array, reqArr, changeReq, valueReq, placeReq) => {
  return array.map(item => {
    return (
        <div className="p-fluid" key={item.name}>
          <div className="p-field p-grid">
            <RequiredSar reqArr={reqArr}
                         name={item.name}
                         key={`star_${item.name}`}/>
            <label htmlFor={item.name} className="p-col-12 p-md-5">{item.label}</label>
            <div className="p-col-12 p-md-6">
              <InputText onChange={changeReq(reqArr, item.name)}
                         value={valueReq(reqArr, item.name)}
                         name={item.name}
                         id={item.name} type="text"
                         placeholder={placeReq(reqArr, item.name)} />
            </div>
          </div>
        </div>
    )
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
