import {
    ORDER_STATUS_LOADED, ORDER_STATUS_UNLOADED
} from "../constants/actionTypes";

const defaultState = {
    orderStatuses: []
};

export default (state = defaultState, action) => {
    let orderStatuses = [];
    if(action.payload &&
        action.payload.hasOwnProperty('error') &&
        action.payload.error === 'Unauthorized') {
        orderStatuses = [];
    } else {
        orderStatuses =  action.payload;
    }
    switch (action.type) {
        case ORDER_STATUS_LOADED:
            return {
                ...state,
                orderStatuses: orderStatuses,
            }
        case ORDER_STATUS_UNLOADED:
            return [];
        default:
            return state;
    }
};
