import {connect} from "react-redux";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import React, {useRef, useState} from "react";
import {Toast} from "primereact/toast";
import agent from "../../agent";
import {LOGOUT} from "../../constants/actionTypes";

const mapStateToProps = state => ({
    ...state.common
})
const mapDispatchToProps = dispatch => ({
    onClickLogout: () => dispatch({ type: LOGOUT }),
})

const UserSettings = props => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);

    const showMessage = (severity, summary, detail) => {
        toast.current.show({severity: severity, summary: summary, detail: detail, sticky: true });
    }

    const sendPasswordChangeForm = (e) => {
        e.preventDefault();
        setLoading(true);
        agent.Auth.changePassword({oldPassword, newPassword, repeatPassword}).then(response => {
            response ?
                showMessage('error', 'Ошибка', response.error) :
                (showMessage('success', 'Запрос выполнен', 'Пароль изменен'),
                setTimeout(() => props.onClickLogout(), 2000));
        }).finally(() => setLoading(false))
    }

    return(
        <div>
            <Toast ref={toast}/>
            <h2>Сменить пароль</h2>
            <form onSubmit={sendPasswordChangeForm}>
                <div className="p-fluid">
                    <div className="p-field p-grid">
                        <label htmlFor="oldPassword" className="p-col-12 p-md-2">Старый пароль</label>
                        <div className="p-col-12 p-md-5">
                            <InputText id="oldPassword" type="password" onChange={(e) => setOldPassword(e.target.value)}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="newPassword" className="p-col-12 p-md-2">Новый пароль</label>
                        <div className="p-col-12 p-md-5">
                            <InputText id="newPassword" type="password" onChange={(e) => setNewPassword(e.target.value)}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="repeatPassword" className="p-col-12 p-md-2">Повторите пароль</label>
                        <div className="p-col-12 p-md-5">
                            <InputText id="repeatPassword" type="password" onChange={(e) => setRepeatPassword(e.target.value)}/>
                        </div>
                    </div>
                    <div className="p-col-6 p-md-2 p-md-offset-5">
                        <Button type="submit" loading={loading}>Сохранить</Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
