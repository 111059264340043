import {REPORT_LOAD, REPORT_UNLOAD} from "../constants/actionTypes";

const defaultState = {
    reports: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case REPORT_LOAD:
            const reports = (action.payload &&
                action.payload.hasOwnProperty('error')) ?
                [] : action.payload;
            return {
                ...state,
                reports: reports,
            }
        case REPORT_UNLOAD:
            return defaultState;
        default:
            return state;
    }
};
