import React from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from "primereact/inputnumber";

export const NewKatoDialog = ({ newKatoBundle }) => {
  const { visible, setVisible, initial, value, setValue, handleChange, onSubmit } = newKatoBundle;

  const onHide = () => { setVisible(false); setValue(initial) };
  const onCancel = () => { onHide() };

  const renderFooter = () => {
    return (
      <div>
        <Button label="Отменить" icon="pi pi-times" type="reset" onClick={onCancel} className="p-button-text" />
        <Button label="Сохранить" icon="pi pi-check" type="button" onClick={onSubmit} autoFocus />
      </div>
    );
  }

  return (
    <Dialog header="Новое направление КАТО" visible={visible} style={{ width: '50vw' }} 
            footer={renderFooter()} onHide={onHide}
    >
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        <InputMask mask="999999999" placeholder={"Откуда КАТО"} slotChar=""
                   value={value.katoFrom} onChange={(e) => {
                     console.log(e)
                     handleChange("katoFrom", e.value)
                   }} />
        <InputMask mask="999999999" placeholder={"Куда КАТО"} slotChar=""
                   value={value.katoTo} onChange={(e) => {
                     console.log(e)
                     handleChange("katoTo", e.value)
                   }} />
        <InputNumber name="days" placeholder={"Срок доставки"}
                     value={value.days} onChange={(e) => { 
                       console.log(e)
                       handleChange("days", e.value) 
                     }} />
      </div>

    </Dialog>
  )
}