import {
    PRODUCT_CATEGORY_LOADED, PRODUCT_CATEGORY_ADD, PRODUCT_CATEGORY_PAGE_OPTIONS
} from "../constants/actionTypes";


const defaultStatus = {
    productCategories: [],
    pageOptions: {
        size: 10,
        totalElements: 0,
        totalPages: 0,
        number: 0
    }
}

export default (state = defaultStatus, action) => {
    switch (action.type) {
        case PRODUCT_CATEGORY_LOADED:
            return {
                ...state,
                productCategories: action.payload,
            }
        case PRODUCT_CATEGORY_PAGE_OPTIONS:
            return {
                ...state,
                pageOptions: action.payload,
            }
        case PRODUCT_CATEGORY_ADD:
            return {
                ...state,
                productCategories: [action.payload, ...state.productCategories],
            }
        default:
            return state;
    }
};
