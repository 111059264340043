import React, {useEffect, useRef, useState, useMemo} from "react";
import {
    INVOICE_LOAD, INVOICE_UNLOAD,
    OFFER_LOADED,
    OFFER_UNLOADED,
    ORDER_CHILDREN_LOADED,
    ORDER_CHILDREN_UNLOADED,
    ORDER_LOADED,
    ORDER_PARCEL_LOADED,
    ORDER_PARCEL_UNLOADED, ORDER_PS_TRACK_RELOADED,
    ORDER_STATUS_LOADED,
    ORDER_UNLOADED, TASK_QUEUE_ADD,
    TASK_QUEUE_LOAD,
    TASK_QUEUE_LOADED,
    TASK_QUEUE_PATCH,
    TASK_QUEUE_UNLOADED
} from "../../constants/actionTypes";
import agent from "../../agent";
import {connect} from "react-redux";
import {BreadCrumb} from "primereact/breadcrumb";
import {history} from "../../store";
import "./order.scoped.css"
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {Dialog} from "primereact/dialog";
import {TabPanel, TabView} from "primereact/tabview";
import {Timeline} from "primereact/timeline";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import ru from "../../locale/calendar";
import {addLocale} from "primereact/api";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {FileUpload} from "primereact/fileupload";
import {dateToString} from "../../utils/dateToString";
import {Toast} from "primereact/toast";
import {Link} from "react-router-dom";
import {classNames} from "primereact/components/utils/ClassNames";
import {InputText} from "primereact/inputtext";
import {confirmDialog} from "primereact/components/confirmdialog/ConfirmDialog";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import {InputSwitch} from 'primereact/inputswitch';
import Invoice from "./Invoice";
import {InputTextarea} from "primereact/inputtextarea";

const mapStateToProps = state => ({
    ...state.order,
    ...state.offer,
    ...state.orderParcel,
    ...state.taskQueue,
    ...state.orderStatus,
    ...state.invoice
});


const mapDispatchToProps = dispatch => ({
    onLoad: (payload, payload2, payload3, payload4, payload5) => {
        dispatch({ type: ORDER_LOADED, payload });
        dispatch({ type: OFFER_LOADED, payload: payload2 });
        dispatch({ type: ORDER_PARCEL_LOADED, payload: payload3 });
        dispatch({ type: ORDER_STATUS_LOADED, payload: payload4 });
        dispatch({ type: TASK_QUEUE_LOADED, payload: payload5 });
    },
    onTaskQueueTab: (payload) =>
        dispatch({ type: TASK_QUEUE_LOAD, payload }),
    onLinkOrderTab: (payload) =>
        dispatch({ type: ORDER_CHILDREN_LOADED, payload }),
    patchTaskQueue: (payload) =>
        dispatch({ type: TASK_QUEUE_PATCH, payload }),
    addTaskQueue: (payload) =>
        dispatch({ type: TASK_QUEUE_ADD, payload }),
    onInvoiceTab: (payload) =>
        dispatch({ type: INVOICE_LOAD, payload }),
    onUnload: () => {
        dispatch({type: ORDER_UNLOADED})
        dispatch({type: OFFER_UNLOADED})
        dispatch({type: ORDER_PARCEL_UNLOADED})
        dispatch({type: TASK_QUEUE_UNLOADED})
        dispatch({type: ORDER_CHILDREN_UNLOADED})
        dispatch({type: INVOICE_UNLOAD})
    },
    reloadOrder: (payload) =>
        dispatch({ type: ORDER_LOADED, payload }),
    updateOrder: (payload) => {
        dispatch({ type: ORDER_LOADED, payload })
    }
});


class RowMenu extends React.Component {
    render() {
        return null;
    }
}

RowMenu.propTypes = {};
const MainViewTabs = props => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeSubIndex, setActiveSubIndex] = useState(0);
    const [showDialog, setShowDialog] = useState(false);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showCreateMessageDialog, setShowCreateMessageDialog] = useState(false);
    const [taskStatus, setTaskStatus] = useState(null);
    const [nextTime, setNexTime] = useState(null);
    const [taskQueueId, setTaskQueueId] = useState(null);

    const [newTaskType, setNewTaskType] = useState(null);
    const [newTaskStatus, setNewTaskStatus] = useState(null);
    const [newNextTime, setNewNextTime] = useState(null);
    const [newFileLabel, setNewFileLabel] = useState(null);
    const [newFileInvoice, setNewFileInvoice] = useState(null);
    const [showCreateTaskError, setShowCreateTaskError] = useState(false);
    const createTaskForm = useRef(null);

    const [orderStatusId, setOrderStatusId] = useState(props?.order?.statusId);
    const [psTrackDialog, setPsTrackDialog] = useState(false);
    const [isDtosVisible, setIsDtosVisible] = useState(false);
    const [packDtos, setPackDtos] = useState();
    const [orderComment, setOrderComment] = useState('');
    const [reason, setReason] = useState('');

    useEffect(() => {
        setOrderComment(props?.order?.notes || '');
    }, [props?.order?.notes]);

    useEffect(() => {
        const dtos = props.order?.packDtos?.map((dto, i) => ({
            index: i+1,
            physWeight: dto?.physWeight,
            dimensions: `${dto?.length}/${dto?.width}/${dto?.height}`,
            volumeWeight: dto?.volumeWeight,
            paidWeight: dto?.weight,
            placeCnt: dto?.placeCnt,
        }));

        setPackDtos(dtos);
    }, [props?.order?.packDtos]);

    useEffect(() => {
        let isActive = true;
        if (isActive === true) {
            setOrderStatusId(props.order.statusId);
            setPsDeliveryDateValue(dateFormat(props.order.psDeliveryDate ?? ''));
            setPsDatePickupValue(dateFormat(props.order.psDatePickup ?? ''));
        }
        return () => {isActive = false}
    }, [props.order.statusId])


    const onTabChange = async (index) => {
        if(index === 3) {
            if(!props.order.track) {
                return;
            }
            agent.Lock.lock(`invoice#${props.order.track}`)
                .then((response) => {
                    setActiveIndex(index);
                    props.onInvoiceTab(agent.Invoices.get(props.order.track));
                }).catch((error) =>  {
                    const data = JSON.parse(error.response.text)
                    props.showMessage('error', 'Ошибка блокировка вкладки', `${data.message}`)
                })
        } else {
            setActiveIndex(index);
            switch (index) {
                case 1:
                    props.onLinkOrderTab(agent.Orders.getChildren(props.order.id))
                    break;
                case 2:
                    props.onTaskQueueTab(agent.TaskQueue.get(props.order.track))
                    break;
            }
        }
        setActiveSubIndex(0);
    };

    const onSubTabChange = (index) => {
        setActiveSubIndex(index);
    };

    const onHide = () => {
        setShowDialog(false)
    }

    const onCreateHide = () => {
        setNewTaskType(null);
        setNewTaskStatus(null);
        setNewNextTime(null);
        setShowCreateDialog(false);
    }

    const onClick = (item) => ev => {
        setShowDialog(true)
        setTaskStatus(item.taskStatus)
        setTaskQueueId(item.id)
        if(item.nextTime) {
            var pattern = /(\d{2})\.(\d{2})\.(\d{4}) (\d{2})\:(\d{2})/;
            setNexTime(new Date(item.nextTime.replace(pattern,'$3-$2-$1 $4:$5')))
        }
    }

    const createTaskQueue = () => {
        setShowCreateDialog(true)
    }

    const ok = () => {
        props.patchTaskQueue(agent.TaskQueue.updateTaskQueue(taskQueueId, {taskStatus, nextTime}));
        setShowDialog(false)
    }

    const sendCreateTaskForm = (e) => {
        e.preventDefault();
        setShowCreateTaskError(false)
        if (newTaskType === "DOWNLOAD_LABEL" && newTaskStatus === "SUCCESS") {
            if (!(newTaskType && newTaskStatus && newNextTime && newFileLabel)) {
                setShowCreateTaskError(true)
                return;
            }
        } else if (!(newTaskType && newTaskStatus  && newNextTime)) {
            setShowCreateTaskError(true)
            return;
        }

        let finalData = {
            'orderNum': props.order.track,
            'task[taskType]': newTaskType,
            'task[taskStatus]': newTaskStatus,
            'task[nextTime]':  dateToString(newNextTime),
            'count': currentTaskQueueType,
            'total': props.taskQueues[0]?.total,
        };

        setNewFileLabel(null);
        setNewFileInvoice(null);

        props.addTaskQueue(agent.TaskQueue.save(finalData, [
            { name: 'label', binary: newFileLabel },
            { name: 'invoice', binary: newFileInvoice }
        ]));

        onCreateHide();
        setShowCreateMessageDialog(true);
        setTimeout(() => {
            setShowCreateMessageDialog(false);
        }, 5000);
    }

    const onStatusUpdate = (taskQueueId) => {
        props.patchTaskQueue(agent.TaskQueue.getById(taskQueueId));
    }

    addLocale('ru', ru);

    const renderFooter = () => {
        return (
            <div>
                <Button label="Нет" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                <Button label="Да" icon="pi pi-check" onClick={() => ok()} autoFocus />
            </div>
        );
    }

    const renderCreateFooter = () => {
        return (
            <div>
                <Button label="Нет" icon="pi pi-times" onClick={() => onCreateHide()} className="p-button-text" />
                <Button form="createTaskForm" type="submit" label="Да" icon="pi pi-check" autoFocus />
            </div>
        );
    }

    const taskStatusList = [{id: 'NEW', nameRu: 'Новая'}, {id: 'BEGIN', nameRu: 'Выполняется'}, {id: 'FAIL', nameRu: 'Ошибка'}, {id: 'SUCCESS', nameRu: 'Успешно'}]

    const actionBodyTemplate = (rowData) => {
        return (
            <RowMenu order={rowData}/>
        );
    }

    const trackBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link className="order__link" onClick={(e) => {
                    e.preventDefault();
                    history.push(`/order/${rowData.track}`)
                    window.location.reload();
                }}>{rowData.track}</Link>
                <Button onClick={() =>  navigator.clipboard.writeText(rowData.track)} className="p-button-secondary p-button-text">
                    <i className="pi pi-copy" style={{'paddingLeft': '2px'}}/>
                </Button>
            </React.Fragment>
        );
    }

    const psTrackBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-6">
                        { (rowData || []).psTrack.map((val, index) => {
                            return (
                                <p key={index}>{val}</p>
                            )
                        })
                        }
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <Button onClick={() =>  navigator.clipboard.writeText((rowData || []).psTrack)} className="p-button-secondary p-button-text">
                            <i className="pi pi-copy" style={{'paddingLeft': '2px'}}/>
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const psDatePickupBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className={classNames('customer-badge')}>{rowData.psDatePickup}</span>
            </React.Fragment>
        );
    }

    const dt = useRef(null);

    const [order, setSelectedOrder] = useState(null);

    const psTracks = (list) => {
        return (
            (list || []).map(val => {
                return (
                    <div className="p-d-flex p-ai-center" key={`${val.id}-${val.orderNum}`}>
                        <input  readOnly defaultValue={val.orderNum}/>
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-text" onClick={(e) => addEditPsTrack('Изменить новый номер заказа грузоперевозчика', { id: val.id, orderNum: val.orderNum, type: 2})}/>
                        <Button icon="pi pi-minus" className="p-button-rounded p-button-text" onClick={(e) => deletePsTrackConfirm(val.id, val.orderNum)}/>
                    </div>
                )
            })
        )
    }

    let events = [];
    let objInArr;

    const transformEvents = () => {
        props.orderParcel.tracks?.map((parcel, index) => {
            objInArr = {
                "date": parcel?.date,
                "code": parcel?.code,
                "status": parcel?.description,
            }
            events.push(objInArr)
        })
    }
    transformEvents()

    // это нужно чтобы дата отображалась в компоненте calendar
    const dateFormat = (date) => {
        const dateParts = date.split('.');
        return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    }

    const [psDeliveryDateValue, setPsDeliveryDateValue] = useState(dateFormat(props.order.psDeliveryDate ?? ''));
    const [psDatePickupValue, setPsDatePickupValue] = useState(dateFormat(props.order.psDatePickup ?? ''));

    const [changeableDataModal, setChangeableDataModal] = useState(false);
    const [psTrack, setPsTrack] = useState({id: null, orderNum: '', type: 0});
    const useClickOutside = (ref) => {
        useEffect(() => {
            function handleClickOutside(e) {
                if (ref.current && !ref.current.contains(e.target)) {
                    setChangeableDataModal(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const changeableDataModalRef = useRef(null);
    useClickOutside(changeableDataModalRef);

    const [showChangeMessage, setShowChangeMessage] = useState(false);

    const changeableDataFormRef = useRef(null);
    const changeableDataFieldsetRef = useRef(null);
    const changeableDataMessage = useRef(null);

    const [psTrackDialogHeader, setPsTrackDialogHeader] = useState('');

    const addEditPsTrack = (header, value) => {
        setPsTrack(value);
        setPsTrackDialogHeader(header)
        setPsTrackDialog(true);
    }

    const sendChangeableDataForm = (e) => {
        e.preventDefault();

        // тут получаем даты потому что они нормально передаются
        const form = document.getElementById('changeableDataForm');
        const formData = new FormData(form);
        const json = JSON.stringify(Object.fromEntries(formData.entries()));
        const data = JSON.parse(json);

        // статус получаем отдельно из state
        const finalData = {
            statusId: orderStatusId,
            psDeliveryDate: data.psDeliveryDate,
            psPickupDate: data.psDatePickup,
            reason: data.reason,
            notes: orderComment
        }

        // тут вместо консоли нужно отправлять
        setChangeableDataModal(false);

        // сообщение после отправки
        setShowChangeMessage(true);

        props.updateOrder(agent.Orders.update(props.order.track, finalData));
        setReason('');
        setTimeout(() => {
            setShowChangeMessage(false);
        }, 3000);
    }

    const fileLabel = useRef(null);
    const fileInvoice = useRef(null);
    const chooseOptions = {label: 'Выбрать файл'};

    const customizedContent = (item) => {
        return (
            <Card title={item.taskType} subTitle={item.createdDate}>

                <div className="card queueCard">
                    <div className="queueLeftSide">
                        <div className="p-field p-grid">
                            <label htmlFor="firstname4" className="p-col-12 p-md-3">Статус</label>
                            <div className="p-col-12 p-md-4">
                                <span>{item.taskStatusName}</span>
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="firstname4" className="p-col-12 p-md-3">Дата начала</label>
                            <div className="p-col-12 p-md-4">
                                <span>{item.startTime}</span>
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="firstname4" className="p-col-12 p-md-3">Дата конца</label>
                            <div className="p-col-12 p-md-4">
                                <span>{item.endTime}</span>
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="firstname4" className="p-col-12 p-md-3">Следующий запуск</label>
                            <div className="p-col-12 p-md-4">
                                <span>{item.nextTime}</span>
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="firstname4" className="p-col-12 p-md-3">Сообщение ошибки</label>
                            <div className="p-col-12 p-md-4">
                                <span>{item.errorMessage}</span>
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="firstname4" className="p-col-12 p-md-3">Трэйс ошибки</label>
                            <div className="p-col-12 p-md-4">
                                <span>{item.errorTrace}</span>
                            </div>
                        </div>
                        <div className="p-field p-grid">
                            <label htmlFor="firstname4" className="p-col-12 p-md-3">Дополнительные данные</label>
                            <div className="p-col-12 p-md-4">
                                <span>{item.data}</span>
                            </div>
                        </div>
                        <Button label="Изменить статус" className="p-button-text" onClick={onClick(item)} />
                    </div>


                   <div className="queueRightSide">
                        <Button
                            onClick={() => onStatusUpdate(item.id)}
                            className="updateQueueStatusBtn"
                            label="Обновить статус"
                            icon="pi pi-refresh"
                        />
                   </div>

                    <Dialog header="Подтверждение" visible={showDialog} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => onHide()}>
                          <div className="card">
                              <div className="p-fluid p-formgrid p-grid">
                                  <div className="p-field p-col-6 p-md-12">
                                      <label htmlFor="firstname6">Статус</label>
                                        <Dropdown inputId="lastname6" value={taskStatus} options={taskStatusList}
                                                  onChange={(e) => {setTaskStatus( e.value)}}
                                                  optionLabel="nameRu" optionValue="id"/>
                                  </div>
                              </div>
                              <div className="p-fluid p-formgrid p-grid">
                                  <div className="p-field p-col-6 p-md-12">
                                      <label htmlFor="firstname6">Следующий запуск</label>
                                      <Calendar value={nextTime} onChange={(e) => setNexTime(e.value)}
                                                readOnlyInput showTime appendTo={document.body} locale='ru'/>
                                  </div>
                              </div>
                          </div>
                    </Dialog>
                </div>
            </Card>
        );
    };

    const onCreatePsTrackHide = () => {
         setPsTrack({id: null, orderNum: '', type: 0});
         setPsTrackDialog(false);
    }

    const okAddEditPsTrack = () => {
        if(!psTrack.orderNum) {
            props.showMessage('error', 'Ошибка', `Заполните обязательное поле номер гурзоперевозчика`);
            return;
        }
        if(psTrack.type === 1) {
            agent.PsOrderNum.add(props.order.id, psTrack.orderNum).then(() => {
                props.showMessage('success', 'Запрос выполнен', `Добавлен новый номер гурзоперевозчика ${psTrack.orderNum}`);
                props.reloadOrder(agent.Orders.get(props.order.track));
            }).finally(() => onCreatePsTrackHide());
        } else if(psTrack.type === 2) {
            agent.PsOrderNum.update(psTrack.id, psTrack.orderNum).then(() => {
                props.showMessage('success', 'Запрос выполнен', `Изменен номер гурзоперевозчика на ${psTrack.orderNum}`);
                props.reloadOrder(agent.Orders.get(props.order.track));
            }).finally(() => onCreatePsTrackHide());
        }
    }

    const deletePsTrackConfirm = (id, orderNum) => {
        confirmDialog({
            message: `Вы действительно хотите удалить номер ${orderNum} грузоперевозчика?`,
            header: 'Удаление номера грузоперевозчика',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                agent.PsOrderNum.remove(id).then(() => {
                    props.showMessage('success', 'Запрос выполнен', `Удален номер гурзоперевозчика ${orderNum}`);
                    props.reloadOrder(agent.Orders.get(props.order.track));
                }).finally(() => onCreatePsTrackHide());
            },
            reject: () => console.log('1')
        });
    }

    const createPsTrackFooter = (props) => {
        return (
            <div>
                <Button label="Нет" icon="pi pi-times" onClick={() => onCreatePsTrackHide()} className="p-button-text" />
                <Button form="createTaskForm"  label="Да" icon="pi pi-check" onClick={(e) => okAddEditPsTrack()} autoFocus/>
            </div>
        );
    }

    const [currentTaskQueueType, setCurrentTaskQueueType] = useState(1);
    const taskQueuesTypes = useMemo(() => {
        if (props.order?.children && props.order?.children?.length) {
            return props.order?.children?.map((subOrder) => {
                return { id: subOrder?.deliveryOrder, name: `Подзаказ ${subOrder?.deliveryOrder}`, value: subOrder?.deliveryOrder };
            });
        }

        return [];
    }, [props?.taskQueues]);

    const getReciverContacts = () => props.order?.receiverContacts?.length ? 
        props.order?.receiverContacts.map((contact, index) => {
            return (
                <div className="tableRow">
                    <label htmlFor="lastname4" className="infoCardLabel">Дополнительный контакт {index+1}</label>
                    <div className="infoCardValue">
                        <input readOnly defaultValue={contact}/>
                    </div>
                </div>
            );
        }) : null;

    return (
        <TabView activeIndex={activeIndex} onTabChange={(e) => onTabChange(e.index)}>
            <TabPanel header="Информация о заказе">
                <TabView style={{marginLeft: '-1rem', marginRight: '-1rem'}} activeIndex={activeSubIndex} onTabChange={(e) => onSubTabChange(e.index)}>
                    <TabPanel header="Основной заказ">
                        <Dialog 
                            header={psTrackDialogHeader} 
                            visible={psTrackDialog} 
                            style={{ width: '30vw' }}
                            footer={createPsTrackFooter()} 
                            onHide={() => onCreatePsTrackHide()}
                        >
                            <div className="card">
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-6 p-md-12">
                                        <InputText id="psOrderNum" 
                                            value={psTrack.orderNum}
                                            onChange={(e) => setPsTrack({id: psTrack.id, orderNum: e.target.value, type: psTrack.type})} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </Dialog>

                        <div>
                            <div className="order-info">
                                <div>
                                    <h1 className="infoCardTitle">Заказ</h1>
                                    <div className="infoCard">
                                        <div className="tableRow">
                                            <label htmlFor="firstname4" className="infoCardLabel">Трек номер SLQ</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.track}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Клиентский номер заказа</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.clientNum}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Клиентский номер доставки</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.clientOrderNum}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Трек номер грузоперевозчика</label>
                                            <div className="infoCardValue">
                                                {psTracks(props.order.psTrack)}
                                            </div>
                                            <div>
                                                <Button icon="pi pi-plus" className="p-button-rounded p-button-text" onClick={() => addEditPsTrack('Добавить новый номер заказа грузоперевозчика', { id: null, orderNum: '', type: 1})}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Клиент</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.orgName}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Цена для клиента, &#8376;</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.price}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Цена товара, &#8376;</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.cargoValue}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Стоимость страхования , &#8376;</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.insuranceValue}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Наклейки</label>
                                            <div className="infoCardValue">
                                                <input readOnly value={props.order.labelDownload ? "Да" : "Нет"}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Накладные</label>
                                            <div className="infoCardValue">
                                                <input readOnly value={props.order.invoiceDownload ? "Да" : "Нет"}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Создал заказ GUID</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.slqUserGuid}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h1 className="infoCardTitle">Доставка</h1>
                                    <div className="infoCard">
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Откуда</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.from}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Куда</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.to}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Количество мест</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.numPack}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Признак доставки</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.deliveryParam}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Контрольная дата доставки</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.deliveryDate}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Контрольная дата забора</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.datePickup}/>
                                            </div>
                                        </div>

                                        <form
                                            ref={changeableDataFormRef}
                                            id="changeableDataForm"
                                            onSubmit={sendChangeableDataForm}
                                        >
                                            <fieldset ref={changeableDataFieldsetRef} id="changeableDataFieldsetId" className="changeableDataFieldset">
                                                <legend>Изменяемые данные</legend>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Статус доставки</label>
                                                    <div className="infoCardValue">

                                                        <Dropdown
                                                            name="statusId"
                                                            inputId="statusesDropdown"
                                                            value={orderStatusId}
                                                            options={props.orderStatuses}
                                                            onChange={(e) => setOrderStatusId(e.value)}
                                                            optionLabel="nameRu" 
                                                            optionValue="id"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Фактическая дата доставки</label>
                                                    <div className="infoCardValue">
                                                        <Calendar
                                                            id="psDeliveryDateCalendar"
                                                            name="psDeliveryDate"
                                                            dateFormat="dd.mm.yy"
                                                            value={psDeliveryDateValue}
                                                            onChange={(e) => setPsDeliveryDateValue(e.value)}
                                                            mask="99.99.9999"
                                                            locale="ru"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Фактическая дата забора</label>
                                                    <div className="infoCardValue">
                                                        <Calendar
                                                            id="psDatePickupCalendar"
                                                            name="psDatePickup"
                                                            dateFormat="dd.mm.yy"
                                                            value={psDatePickupValue}
                                                            onChange={(e) => setPsDatePickupValue(e.value)}
                                                            mask="99.99.9999"
                                                            locale="ru"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Комментарии к заказу</label>
                                                    <div className="infoCardValue">
                                                        <InputTextarea style={{width: "130%"}} value={orderComment} onChange={(e) => setOrderComment(e.target.value)} />
                                                    </div>
                                                </div>

                                                <button
                                                    onClick={() => {
                                                        document.location.reload()
                                                    }}
                                                    className="changeableDataReset"
                                                    type="button"
                                                >
                                                    сбросить
                                                </button>

                                                <button
                                                    onClick={() => setChangeableDataModal(true)}
                                                    type="button"
                                                >
                                                    сохранить изменения
                                                </button>

                                                {changeableDataModal && (
                                                    <div id='changeableDataModalId' ref={changeableDataModalRef} className="changeableDataModal">
                                                        <h3>Вы уверены что хотите изменить данные?</h3>
                                                        <h4>Причина изменения данных:</h4>
                                                        <textarea 
                                                            value={reason}
                                                            onChange={(e) => setReason(e?.target?.value)}
                                                            style={{width: '100%'}} 
                                                            placeholder={"обязательное поле"} 
                                                            id="changeableDataModalReason" 
                                                            name="reason" 
                                                        />
                                                        <button
                                                            onClick={() => setChangeableDataModal(false)}
                                                            type="button"
                                                        >
                                                            отмена
                                                        </button>
                                                        <button
                                                            onClick={(e) => {
                                                                if (reason) return true;
                                                                e.preventDefault();
                                                                return;
                                                            }}
                                                            type="submit"
                                                            disabled={!reason}
                                                            className="changeableDataModalConfirm"
                                                        >
                                                            подтвердить изменение данных
                                                        </button>
                                                    </div>
                                                )}

                                                {showChangeMessage && (
                                                    <div ref={changeableDataMessage} className="changeableDataModal">
                                                        Ваш запрос отправлен, данные будут обновлены спустя какое-то время
                                                    </div>
                                                )}
                                            </fieldset>
                                        </form>

                                    </div>
                                </div>

                                <div>
                                    <h1 className="infoCardTitle">Перевозчик</h1>
                                    <div className="infoCard">
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Грузоперевозчик</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.psName}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Вид услуги Грузоперевозчика</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.serviceType}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Контрольная дата доставки Грузоперевозчика</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.psCheckDeliveryDate}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Прогноз стоимости доставки, &#8376;</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.psActualPrice}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h1 className="infoCardTitle">Отправитель</h1>
                                    <div className="infoCard">
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Отправитель</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.sender}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Отправитель (БИН ИИН)</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.senderInnOrBin}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Данные отправителя по доставке</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.senderInfo}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Адрес</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.senderAddress}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h1 className="infoCardTitle">Посылка</h1>
                                    <div className="infoCard">
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Груз</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.productName}/>
                                            </div>
                                        </div>
                                        {props.order.weight ?
                                            <div className="tableRow">
                                                <label htmlFor="lastname4" className="infoCardLabel">Физический вес отправки</label>
                                                <div className="infoCardValue">
                                                    <input readOnly defaultValue={props.order.weight}/>
                                                </div>
                                            </div> : null
                                        }
                                        {props.order.volumeWeight ?
                                            <div className="tableRow">
                                                <label htmlFor="lastname4" className="infoCardLabel">Объемный вес отправки</label>
                                                <div className="infoCardValue">
                                                    <input readOnly defaultValue={props.order.volumeWeight}/>
                                                </div>
                                            </div> : null
                                        }
                                        {props.order.paidWeight ?
                                            <div className="tableRow">
                                                <label htmlFor="lastname4" className="infoCardLabel">Платный вес</label>
                                                <div className="infoCardValue">
                                                    <input readOnly defaultValue={props.order.paidWeight}/>
                                                </div>
                                            </div> : null
                                        }
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Вес от ГП</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.psActualWeight}/>
                                            </div>
                                        </div>

                                        {packDtos?.length ?
                                            <div className="p-d-flex p-jc-end p-my-3">
                                                <InputSwitch checked={isDtosVisible} onChange={(e) => setIsDtosVisible(e.value)} />
                                            </div> : null
                                        }
                                        
                                        {isDtosVisible && packDtos?.length ?
                                            <div className="card">
                                                <DataTable scrollable scrollHeight="400px" value={packDtos} className="p-datatable-sm">
                                                    <Column field="index" header="Посылка"></Column>
                                                    <Column field="physWeight" header="Физический вес"></Column>
                                                    <Column field="dimensions" header="Дл*Шир*Выс"></Column>
                                                    <Column field="volumeWeight" header="Объемный вес"></Column>
                                                    <Column field="paidWeight" header="Платный вес"></Column>
                                                    <Column field="placeCnt" header="Количество"></Column>
                                                </DataTable>
                                            </div> : null
                                        }
                                    </div>
                                </div>

                                <div>
                                    <h1 className="infoCardTitle">Получатель</h1>
                                    <div className="infoCard">
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Получатель</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.receiver}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Получатель (БИН ИИН)</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.receiverInnOrBin}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Данные получателя по доставке</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.receiverInfo}/>
                                            </div>
                                        </div>
                                        <div className="tableRow">
                                            <label htmlFor="lastname4" className="infoCardLabel">Адрес</label>
                                            <div className="infoCardValue">
                                                <input readOnly defaultValue={props.order.receiverAddress}/>
                                            </div>
                                        </div> 
                                        {getReciverContacts()}
                                        {props?.order?.receiverActual && 
                                            <div className="tableRow">
                                                <label htmlFor="lastname4" className="infoCardLabel">Фактический получатель</label>
                                                <div className="infoCardValue">
                                                    <input readOnly defaultValue={props.order.receiverActual}/>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="timelineContainer">
                                <h2 className="timelineTitle">Трэк данные</h2>
                                <Timeline
                                    value={events}
                                    layout="horizontal"
                                    content={(item) =>
                                        <div style={{}}>
                                            {item.date ? (
                                                <>
                                                    <div className="timelineData">{item.date.slice(0, 10)}</div>
                                                    <div className="timelineData">{item.date.slice(10).trim()}</div>
                                                </>
                                            ) : (
                                                <div className="timelineData">Нет даты</div>
                                            )}
                                            <div className="timelineData">{item.code}</div>
                                            <div className="timelineData">{item.status}</div>
                                        </div>
                                    }
                                    align="left"
                                    className="p-timeline-left"
                                />
                            </div>

                        </div>
                    </TabPanel> 
                    
                    <TabPanel header={"Подзаказы"} disabled={!props.order?.children?.length}>
                        {props.order?.children?.map((subOrder, index) => {
                            return (
                                <div key={index} className="sub-order">
                                    <h1 className="orderTitle">Подзаказ {index+1}</h1>
                                    <div className="order-info">
                                        <div>
                                            <h1 className="infoCardTitle">Заказ</h1>
                                            <div className="infoCard">
                                                <div className="tableRow">
                                                    <label htmlFor="firstname4" className="infoCardLabel">Трек номер SLQ</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.track}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Грузоперевозчик</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.psName}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Вид услуги Грузоперевозчика</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.serviceType}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Прогноз стоимости доставки, &#8376;</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.psActualPrice}/>
                                                    </div>
                                                </div>
                                                {subOrder.psName === 'PTC' ?
                                                    <div className="tableRow">
                                                        <label htmlFor="lastname4" className="infoCardLabel">Себестоимость ЧГП</label>
                                                        <div className="infoCardValue">
                                                            <input readOnly defaultValue={subOrder.costPrice}/>
                                                        </div>
                                                    </div> : null
                                                }
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Вес от ГП</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.psActualWeight}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Контрольная дата забора</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.datePickup}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Контрольная дата доставки</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.deliveryDate}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h1 className="infoCardTitle">Доставка</h1>
                                            <div className="infoCard">
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Откуда</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.from}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Куда</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.to}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Признак доставки</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.deliveryParam}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Статус доставки</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.status}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Фактическая дата забора</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.psDatePickup}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Фактическая дата доставки</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.psDeliveryDate}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Подзаказ успешно завершен</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.done ? 'Да' : 'Нет'}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Начался следующий подзаказ</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.runNext ? 'Да' : 'Нет'}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h1 className="infoCardTitle">Отправитель</h1>
                                            <div className="infoCard">
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Отправитель</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.sender}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Отправитель (БИН ИИН)</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.senderInnOrBin}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Данные отправителя по доставке</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.senderInfo}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Адрес</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.senderAddress}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h1 className="infoCardTitle">Получатель</h1>
                                            <div className="infoCard">
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Получатель</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.receiver}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Получатель (БИН ИИН)</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.receiverInnOrBin}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Данные получателя по доставке</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.receiverInfo}/>
                                                    </div>
                                                </div>
                                                <div className="tableRow">
                                                    <label htmlFor="lastname4" className="infoCardLabel">Адрес</label>
                                                    <div className="infoCardValue">
                                                        <input readOnly defaultValue={subOrder.receiverAddress}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </TabPanel>
                </TabView>
            </TabPanel>

            <TabPanel header="Связанные заказы">
                <DataTable ref={dt} value={props.orderChildren}
                           className="p-datatable-customers" dataKey="id" rowHover selection={order}
                           emptyMessage="Данные не найдены" scrollable>
                    <Column headerStyle={{ width: '250px' }} columnKey="track" field="track" header="Номер заказа" body={trackBodyTemplate} sortable/>
                    <Column headerStyle={{ width: '225px' }} columnKey="psTrack" field="psTrack" header="Трек номер грузоперевозчика" body={psTrackBodyTemplate} sortable/>
                    <Column headerStyle={{ width: '250px' }} columnKey="psName" field="psName" header="Грузоперевозчик" sortable />
                    <Column headerStyle={{ width: '250px' }} columnKey="orgName" field="orgName" header="Клиент" sortable />
                    <Column headerStyle={{ width: '250px' }} columnKey="status" field="status" header="Статус доставки" sortable />
                    <Column headerStyle={{ width: '100px' }} columnKey="psDatePickup" field="psDatePickup" header="Фактическая дата забора" body={psDatePickupBodyTemplate} sortable />
                </DataTable>
            </TabPanel>
            
            <TabPanel header="Данные очереди команд">
                <div className="card">
                    {props.taskQueues[0]?.count === null ? null :
                        <div className="select-suborder">
                            <label htmlFor="psId">Выбрать подзаказ:</label>
                            <Dropdown 
                                inputId="psId" 
                                value={currentTaskQueueType}
                                options={taskQueuesTypes}
                                onChange={(e) => {
                                    setCurrentTaskQueueType(e.value)
                                }}
                                optionLabel="name"
                                optionValue="value"
                            />
                        </div>
                    }
                    {props.taskQueues[0]?.total ? 
                        <div className="createTaskQueueBtn">
                            <Button
                                onClick={() => createTaskQueue()}
                                label="Создать новое состояние"
                                icon="pi pi-plus"
                            />
                        </div> : null
                    }
                    <hr />

                    <Dialog header="Создание" visible={showCreateDialog} style={{ width: '50vw' }} footer={renderCreateFooter()} onHide={() => onCreateHide()}>
                        <form ref={createTaskForm} id="createTaskForm" onSubmit={sendCreateTaskForm}>
                            {showCreateTaskError && (
                                <div className="errorEmptyInputs">Заполните обязательные поля</div>
                            )}
                            <div className="card">
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-6 p-md-12">
                                        <label htmlFor="firstname6">Тип</label>
                                        <Dropdown name="taskType" inputId="lastname6" value={newTaskType} options={props.taskQueueTypes}
                                                    onChange={(e) => setNewTaskType(e.value)}
                                                    optionLabel="nameRu" optionValue="type"/>
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-6 p-md-12">
                                        <label htmlFor="firstname6">Статус</label>
                                        <Dropdown name="taskStatus" inputId="lastname6" value={newTaskStatus} options={taskStatusList}
                                                    onChange={(e) => setNewTaskStatus(e.value)}
                                                    optionLabel="nameRu" optionValue="id"/>
                                    </div>
                                </div>
                                {newTaskType === "DOWNLOAD_LABEL" && newTaskStatus === "SUCCESS" && (
                                    <div className="uploadInputs">

                                        <div className="uploadInput">
                                            <label htmlFor="firstname6">Загрузите наклейку</label>
                                            <FileUpload
                                                ref={fileLabel} name="label" mode="basic"
                                                chooseOptions={chooseOptions} onSelect={(e) => setNewFileLabel(e.files[0])} />
                                        </div>

                                        <div className="uploadInput">
                                            <label htmlFor="firstname6">Загрузите накладную</label>
                                            <FileUpload
                                                ref={fileInvoice} name="invoice" mode="basic"
                                                chooseOptions={chooseOptions} onSelect={(e) => setNewFileInvoice(e.files[0])} />
                                        </div>
                                    </div>
                                )}
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-6 p-md-12">
                                        <label htmlFor="firstname6">Следующий запуск</label>
                                        <Calendar 
                                            name="nextTime" 
                                            value={newNextTime} 
                                            onChange={(e) => setNewNextTime(e.value)}
                                            readOnlyInput 
                                            showTime 
                                            appendTo={document.body} 
                                            locale='ru'
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Dialog>

                    <Dialog 
                        header="Запрос на создание нового состояния отправлен" 
                        visible={showCreateMessageDialog}
                        style={{ width: '50vw' }} 
                        onHide={() => {}}
                    />

                    {props.taskQueues
                        .filter((task) => task.count === currentTaskQueueType || task.count === null)
                        .map((item, index) => {
                            return (
                                <div style={{ marginTop: '2em' }} key={index}>{customizedContent(item)}</div>
                            )
                        })
                    }
                </div>
            </TabPanel>

            <TabPanel header="Счета клиента SLQ.KZ">
                <Invoice invoices={props.invoices}
                         track={props.order.track}
                         onInvoiceTab={props.onInvoiceTab}
                         setActiveIndex={setActiveIndex}/>

            </TabPanel>
        </TabView>
    )
}

const PageBreadCrumb = (props) => {
    const items = [
        { label: 'Заказы' , command:()=> { history.push(`/orders`) }},
        { label: props.track }
    ];

    const home = { icon: 'pi pi-home',  command:()=> { history.push(`/admin`)}}

    return (
        <BreadCrumb model={items} home={home}/>
    )
}


const CardFooter = (props) => {
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [position, setPosition] = useState('center');
    const [disabled, setDisabled] = useState(true);
    const [newPrice, setNewPrice] = useState(props.price);
    const onClick = (position) => {
        setDisplayConfirmation(true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = () => {
        setDisplayConfirmation(false);
        setDisabled(true);
        setNewPrice(props.price);
    }


    const ok = () => {
        const offerPrice = disabled ? null : newPrice
        agent.Offers.change(props.offer, { orderId: props.id, offerPrice })
            .then(res => {
                props.showMessage('success', 'Запрос выполнен', `Заказ создан под номером ${res.orderNum}`);
            }).catch((error) => {
                props.showMessage('error', 'Ошибка', `${error.response.text}`)
            }).finally(() => onHide());
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Нет" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                <Button label="Да" icon="pi pi-check" onClick={() => ok()} autoFocus />
            </div>
        );
    }

    return (
        <div>
            <Button label="Активировать" icon="pi pi-check" onClick={() => onClick()} disabled={props.active}/>
            <Dialog header="Подтверждение" visible={displayConfirmation} modal style={{ width: '350px' }} footer={renderFooter()} onHide={() => onHide()}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span>Вы действительно хотите изменить поставщика?</span>
                    <div className="p-d-flex p-ai-center p-mt-4">
                        <Checkbox className="p-mr-2" onChange={e => {
                            setDisabled(e.checked);
                            if(e.checked) {
                                setNewPrice(props.price);
                            }
                        }} checked={disabled}/>
                        <InputNumber className="p-mr-2" value={newPrice} onValueChange={(e) => setNewPrice(e.value)} disabled={disabled}/>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

const AlternateOffer = props => {

    const offers = props.offers?.length ? props.offers.map((offer, index) => {
            return (
                    <span className="p-overlay-badge" style={{ width: '20rem', marginRight: '3em' }}
                          key={index}>
                       <Card title={offer.psName} subTitle={offer.serviceType}
                             className="ui-card-shadow"
                             footer={null}>
                           {offer.active ? <span className="p-badge p-badge-l p-badge-warning">Активный</span> : null}
                           <p className="p-m-0" style={{lineHeight: '1.5'}}>Цена от SLQ: {offer.price} &#8376;</p>
                           <p className="p-m-0" style={{lineHeight: '1.5'}}>Цена от ТК: {offer.psPrice} &#8376;</p>
                           <p className="p-m-0" style={{lineHeight: '1.5'}}>Срок: {offer.deliveryTime} дн.</p>
                       </Card>
                   </span>
            )
    }) : null;

    return(
        <div className="p-d-flex" style={{marginTop: '2em'}}>{offers}</div>
    )
}

class MainView extends React.Component {

    constructor(props) {
        super(props)
        this.downloadFile = (prefixName, type, track) => ev => {
            agent.Orders.getFile(track, type)
                .then(res => {
                    const contentDisposition = res.headers['content-disposition'];
                    if(contentDisposition) {
                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        const matches = filenameRegex.exec(contentDisposition);
                        if (matches != null && matches[1]) {
                            let filename = matches[1].replace(/['"]/g, '');
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(res.body);
                            link.download = decodeURI(filename).replace('UTF-8', '');
                            link.click();
                        }
                    }
                });
        }
        this.showMessage = this.showMessage.bind(this);
    }

    showMessage(severity, summary, detail) {
        this.toast.show({severity: severity, summary: summary, detail: detail, sticky: true });
    }

    componentDidMount() {
        this.props.onLoad(
            agent.Orders.get(this.props.match.params.orderNum),
            agent.Offers.get(this.props.match.params.orderNum),
            agent.OrderParcel.get(this.props.match.params.orderNum),
            agent.OrdersStatus.all(),
            agent.TaskQueue.all()
        );
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    render() {
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <PageBreadCrumb track={this.props.order.track} />
                <div className="p-field p-grid p-p-lg-4">
                    <Button label="Скачать накладную" className="p-mr-2 p-mb-2" onClick={this.downloadFile('Накладная', 'INVOICE', this.props.order.track)}/>
                    <Button label="Скачать наклейку" className="p-mb-2" onClick={this.downloadFile('Наклейка', 'LABEL', this.props.order.track)}/>
                </div>
                <MainViewTabs order={this.props.order}
                              orderStatuses={this.props.orderStatuses}
                              orderChildren={this.props.orderChildren}
                              orderParcel={this.props.orderParcel}
                              onTaskQueueTab={this.props.onTaskQueueTab}
                              onInvoiceTab={this.props.onInvoiceTab}
                              patchTaskQueue={this.props.patchTaskQueue}
                              addTaskQueue={this.props.addTaskQueue}
                              onLinkOrderTab={this.props.onLinkOrderTab}
                              taskQueues={this.props.taskQueues}
                              taskQueueTypes={this.props.taskQueueTypes}
                              invoices={this.props.invoices}
                              updateOrder={this.props.updateOrder}
                              reloadOrder={this.props.reloadOrder}
                              showMessage={this.showMessage}/>

                    <AlternateOffer offers={this.props.offers}
                                    showMessage={this.showMessage}
                                    orderId={this.props.order.id}/>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainView);