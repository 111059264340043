import React from "react";
import agent from "../../../agent";
import {PRODUCT_CATEGORY_LOADED, PRODUCT_CATEGORY_PAGE_OPTIONS} from "../../../constants/actionTypes";
import {connect} from "react-redux";
import PostalServiceTable from "./components/PostalService";
import "./style.css";

const mapStateToProps = state => ({
    ...state.productCategory,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (data, pageOptions) => {
        dispatch({ type: PRODUCT_CATEGORY_LOADED, payload: data });
        dispatch({ type: PRODUCT_CATEGORY_PAGE_OPTIONS, payload: pageOptions });
    }
});

class ProductCategory extends React.Component {

    constructor(props) {
        super(props);
    }

    async fetchData() {
        const response = await agent.ProductCategory.all();
        const pageOptions = {
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            number: response.number
        }
        this.props.onLoad(response.content, pageOptions);
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        return (
            <PostalServiceTable />
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProductCategory);
