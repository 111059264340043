import {
    ORDER_CHILDREN_LOADED,
    ORDER_CHILDREN_UNLOADED,
    ORDER_LOADED,
    ORDER_PAGE_LOADED, ORDER_PAGE_UNLOADED, ORDER_PS_TRACK_RELOADED, ORDER_UNLOADED
} from "../constants/actionTypes";


const defaultState = {
    order: {},
    orders: [],
    orderChildren: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ORDER_PAGE_LOADED:
            return {
                ...state,
                orders: action.payload,
            }
        case ORDER_PAGE_UNLOADED:
            return defaultState;
        case ORDER_LOADED:
            return {
                ...state,
                order: action.payload,
            }
        case ORDER_PS_TRACK_RELOADED:
            const newArray = [...state.order.psTrack];
            action.payload.psTrack.forEach((track) => {
               const index = newArray.findIndex(psTrack => psTrack.id === track.id &&
                   psTrack.orderNum !== track.orderNum);
               if(index !== -1) {
                   newArray[index] = track;
               }
            })

            return {
                ...state,
                order: {
                    ...state.order,
                    psTrack: newArray
                }
            }
        case ORDER_CHILDREN_LOADED:
            return {
                ...state,
                orderChildren: action.payload,
            }
        case ORDER_CHILDREN_UNLOADED:
        case ORDER_UNLOADED:
            return defaultState;
        default:
            return state;
    }
};
