import {
  CHANGE_REQUIRED_FIELD, CLEAR_ALL_FIELDS, CHANGE_NOT_REQUIRED_FIELD
} from '../constants/actionTypes'

const defaultState = {
  requiredFields: {
    pickupDate: '',
    sendLawName: '',
    sendName: '',
    sendSurname: '',
    sendIinBin: '',
    sendBuilding: '',
    sendPhone: '',
    sendStreet: '',
    receiveLawName: '',
    receiveName: '',
    receiveSurname: '',
    receiveIinBin: '',
    receiveBuilding: '',
    receivePhone: '',
    receiveStreet: ''
  },
  notRequiredFields: {
    sendEmail: '',
    sendPavilion: '',
    sendPatronymic: '',
    sendHousing: '',
    sendOwnership: '',
    sendOffice: '',
    sendStr: '',
    sendApartment: '',
    receiveEmail: '',
    receivePavilion: '',
    receivePatronymic: '',
    receiveHousing: '',
    receiveOwnership: '',
    receiveOffice: '',
    receiveStr: '',
    receiveApartment: '',
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_REQUIRED_FIELD:
      return {
        ...state,
        requiredFields: {
          ...state.requiredFields,
          [action.name]: action.value
        }
      }
    case CHANGE_NOT_REQUIRED_FIELD:
      return {
        ...state,
        notRequiredFields: {
          ...state.notRequiredFields,
          [action.name]: action.value
        }
      }
    case CLEAR_ALL_FIELDS:
      return state

    default:
      return state
  }
}
