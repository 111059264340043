import React, {useRef, useState} from "react";
import agent from "../../agent";
import {
    POSTAL_SERVICE_PAGE_LOADED,
    POSTAL_SERVICE_PAGE_UNLOADED
} from "../../constants/actionTypes";
import {connect} from "react-redux";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {ContextMenu} from "primereact/contextmenu";

const mapStateToProps = state => ({
    ...state.postalService,
    appName: state.common.appName,
    token: state.common.token
});

const mapDispatchToProps = dispatch => ({
    onLoad: (payload) =>
        dispatch({ type: POSTAL_SERVICE_PAGE_LOADED, payload }),
    onUnload: () =>
        dispatch({  type: POSTAL_SERVICE_PAGE_UNLOADED })
});

const PostalServiceTable = props => {
    const [selectedPostalService, setSelectedPostalService] = useState(null);
    const dt = useRef(null);
    const toast = useRef(null);
    const cm = useRef(null);

    const menuModel = [
        {label: 'Разблокировать', command: () => oneAction(selectedPostalService, 'ACTIVE')},
        {label: 'Блокировать', command: () => oneAction(selectedPostalService, 'BLOCKED')},
    ];

    const oneAction = (route, status) => {
        props.onSetStatus(agent.PostalService.setStatus(route.id, status));
    }

    return (
        <div className="datatable-doc-demo">
            <Toast ref={toast}/>
            <ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedPostalService(null)}/>
            <div className="card">
                <DataTable ref={dt} value={props.postalServices||[]}
                           className="p-datatable-customers" dataKey="id" rowHover
                           contextMenuSelection={selectedPostalService}
                           onContextMenuSelectionChange={e => setSelectedPostalService(e.value)}
                           onContextMenu={e => cm.current.show(e.originalEvent)} paginator rows={10}
                           emptyMessage="Данные не найдены" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           rowsPerPageOptions={[10,25,50]}>
                    <Column headerStyle={{ width: '250px' }} columnKey="id" field="id" header="ID"  sortable/>
                    <Column headerStyle={{ width: '250px' }} columnKey="nameRu" field="nameRu" header="Наименование" sortable />
                    <Column headerStyle={{ width: '250px' }} columnKey="productCode" field="productCode" header="Код услуги" sortable />
                    <Column headerStyle={{ width: '250px' }} columnKey="statusType" field="statusType" header="Статус" sortable />
                </DataTable>
            </div>
        </div>
    );
}

class PostalService extends React.Component {

    componentDidMount() {
        this.props.onLoad(agent.PostalService.all());
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    render() {
        return (<PostalServiceTable postalServices={this.props.postalServices} onSetStatus={this.props.onLoad}/>)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PostalService);
