import {
    SLQ_USER_LOAD,
    SLQ_USER_UNLOAD,
    SLQ_USER_WALLET_HISTORY_LOAD,
    SLQ_USER_WALLET_LOAD,
    SLQ_USER_WALLET_UNLOAD,
    SLQ_USER_WALLET_HISTORY_UNLOAD,
    SLQ_USER_STATUSES,
    SLQ_USER_AVRS
} from "../../constants/actionTypes";
import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import agent from "../../agent";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Toast} from "primereact/toast";
import {Fieldset} from "primereact/fieldset";

const mapStateToProps = state => ({
    ...state.slqUser,
    ...state.avrs,
    ...state.statuses
});

const mapDispatchToProps = dispatch => ({
    onLoad: (payload) => {
        dispatch({ type: SLQ_USER_LOAD, payload });
    },
    onWalletLoad: (payload) => {
        dispatch({ type: SLQ_USER_WALLET_LOAD, payload });
    },
    onWalletHistoryLoad: (payload) => {
        dispatch({ type: SLQ_USER_WALLET_HISTORY_LOAD, payload });
    },
    onUnload: () => {
        dispatch({ type: SLQ_USER_UNLOAD });
        dispatch({ type: SLQ_USER_WALLET_UNLOAD });
        dispatch({ type: SLQ_USER_WALLET_HISTORY_UNLOAD });
    },
    onStatuses: (payload) => {
        dispatch({ type: SLQ_USER_STATUSES, payload });
    },
    onAvrsLoad: (payload) => {
        dispatch({ type: SLQ_USER_AVRS, payload });
    }
});

const SLQUser = props => {
    const currentPath = window.location.pathname;
    const userId = currentPath.split('/').reverse()[0]
    const initialFilters = {
        userId,
        status: '',
        avrNum: ''
    };
    const initialAvrStatus = {
        avrId: null,
        statusId: null
    };
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
    const [percent, setPercent] = useState(null);
    const [limit, setLimit] = useState(null);
    const [filters, setFilters] = useState(initialFilters);
    const [discountType, setDiscountType] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [selectedAvrStatus, setSelectedAvrStatus] = useState(initialAvrStatus);
    const createTaskForm = useRef(null);
    const toast = useRef(null);
    const onPageChange = (event) => {
        setPage(event.page)
        setPageSize(event.rows)
        setFirst(event.first)

        if (props.slqUser?.realmId === 'slq_jur') {
            props.onAvrsLoad(agent.SlqUser.getAvrs(filters, event.rows, event.page));
        } else {
            props.onWalletHistoryLoad(agent.SlqUser.getWalletHistory(props.match.params.guid, event.page, event.rows));
        }
    }

    const showMessage = (severity, summary, detail) => {
        toast.current.show({severity: severity, summary: summary, detail: detail, sticky: true });
    }

    const showActivationDialog = () => {
        const response =  agent.Tariff.getTariff(props.match.params.guid)
        response.then(res => {
            if(res.body) {
                setLimit(res.body.limit)
                setDiscountType(res.body.discountType)
                setPercent(res.body.percent)
                setPaymentType('AFTER')
            }
        })
        setShowCreateDialog(true)
    }

    useEffect(() => {
        props.onAvrsLoad(agent.SlqUser.getAvrs(filters, pageSize, page));
        props.onStatuses(agent.SlqUser.getStatuses());
        props.onLoad(agent.SlqUser.get(props.match.params.guid));
        props.onWalletLoad(agent.SlqUser.getWallet(props.match.params.guid));
        props.onWalletHistoryLoad(agent.SlqUser.getWalletHistory(props.match.params.guid, page, pageSize));
        return function cleanup() {
            props.onUnload()
        };
    }, []);

    const label = {
        "locale": "Локаль",
        "companyName": "Наименование компании",
        "bin": "БИН",
        "location": "Местонахождение",
        "legalAddress": "Адрес"
    }
    const Attributes = props => {
        const isVisible = (str) => {
            return str && str !== "";
        }

        return (
            <div>
            {
                 Object.keys((props.attributes||{})).map((key, id) => {
                     return (
                         <div className="tableRow" key={id} style={{visibility: isVisible(props.attributes[key]) ? 'visible' : 'hidden'}}>
                             <label htmlFor={key} className="infoCardLabel">{label[key]}</label>
                             <div className="infoCardValue">
                                 <input id={key} readOnly defaultValue={props.attributes[key]}/>
                             </div>
                         </div>
                     )
                 })
             }
            </div>
        )}
    const amountBodyTemplate = (rowData) => {
        return (
            <div>
                <span> {(rowData.type === 'DEBIT' ? '-' : '+').concat(rowData.amount)} </span>
            </div>
        );
    }

    const discountTypeSelectItems = [
        {label: 'Надбавка на себестоимость ГП', value: 'DISCOUNT'},
        {label: 'Скидка на стандарт SLQ.kz', value: 'STANDARD'},
        {label: 'Индивидуальный', value: 'INDIVIDUAL'}
    ];

    const paymentTypeSelectItems = [
        {label: 'Предоплата', value: 'BEFORE'},
        {label: 'Постоплата', value: 'AFTER'}
    ];

    const onCreateHide = () => {
        setShowCreateDialog(false);
    }
    const renderCreateFooter = () => {
        return (
            <div>
                <Button label="Нет" icon="pi pi-times" onClick={() => onCreateHide()} className="p-button-text" />
                <Button form="createForm" type="submit" label="Да" icon="pi pi-check" autoFocus />
            </div>
        );
    }

    const renderFooter = () => {
        const handleSave = async () => {
            try {
                const result = await agent.SlqUser.setStatus(selectedAvrStatus.avrId);
                if (result.id) {
                    props.onAvrsLoad(agent.SlqUser.getAvrs(initialFilters, 10, 0));
                    setFilters(initialFilters); setPageSize(10); setPage(0);
                    showMessage('success', 'Запрос выполнен', `Статус изменен`);
                    setShowChangeStatusDialog(false);
                }
            } catch(err) {
                if (err.status === 400) {
                    showMessage('error', 'Ошибка', `Данный статус уже присвоен`);
                } else {
                    showMessage('error', 'Ошибка', `Статус не изменен`);
                }
            }
        };

        return (
            <div>
                <Button
                    label="Отменить"
                    icon="pi pi-times"
                    onClick={() => setShowChangeStatusDialog(false)}
                    className="p-button-text"
                />
                <Button
                    label="Сохранить"
                    icon="pi pi-check"
                    form="createForm"
                    onClick={handleSave}
                    autoFocus
                />
            </div>
        );
    };

    const sendCreateForm = (e) => {
        e.preventDefault();
        const data = {
            percent,
            discountType,
            paymentType,
            userId: props.match.params.guid,
            limit: limit
        };
        agent.Tariff.save(data).then((response) =>  {
            showMessage('success', 'Запрос выполнен', `Пользователь активирован `);
            onCreateHide();
        }).catch((e) => {
            showMessage('error', 'Ошибка', `пользователь не активирован ${e}`);
        });
    }

    const handleSearchAvrs = (e) => {
        e.preventDefault(); 
        props.onAvrsLoad(agent.SlqUser.getAvrs(filters, 10, 0));
        setPageSize(10); setPage(0);
    }

    const Table = () => {
        const [expandedRows, setExpandedRows] = useState([]);
        const [row, setRow] = useState(null);
        

        const handleModifyStatus = (val) => {
            setSelectedAvrStatus({
                avrId: val.id,
                statusId: val.status+1
            })
            setShowChangeStatusDialog(true);
        };

        const getStatusValue = (val) => {
            return (
                <div className="p-d-flex p-jc-between">
                    <span className="p-mr-2">{(props?.statuses?.find((stat) => stat?.id === val?.status) || {name: ''}).name}</span>
                    {val.status === 3 ? null : 
                        <Button 
                            onClick={() => handleModifyStatus(val)} 
                            className="p-button-text p-button-rounded" 
                            icon="pi pi-pencil" 
                        />
                    }
                </div>
            )
        };

        const rowExpansionTemplate = (data) => {
            return (
                <div className="orders-subtable">
                    <h4>Позиции в АВР</h4>
                    <DataTable resizableColumns  columnResizeMode="fit" value={data.lines}>
                        <Column field="name" header="Наименование услуги" style={{width:'20%'}} />
                        <Column field="count" header="Количесто" style={{width:'10%'}} />
                        <Column field="weight" header="Вес" style={{width:'10%'}} />
                        <Column field="totalWN" header="Сумма без НДС" style={{width:'10%'}} />
                        <Column field="nds" header="НДС" style={{width:'10%'}} />
                        <Column field="total" header="Сумма" style={{width:'10%'}} />
                        <Column field="info" header="Примечание" />
                    </DataTable>
                </div>
            );
        }

        return (
            <div className="card">
                <DataTable  value={props.avrs?.content} expandedRows={expandedRows} 
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            selection={row} onSelectionChange={e => setRow(e.value)}
                            rowExpansionTemplate={rowExpansionTemplate} dataKey="id">
                    <Column expander style={{ width: '3em' }} />
                    <Column field="avrNum" header="№АВР" />
                    <Column field="startDate" header="Начальная дата" />
                    <Column field="endDate" header="Конечная дата" />
                    <Column field="totalAmount" header="Сумма" />
                    <Column field="status" body={getStatusValue} header="Статус" />
                </DataTable>
                <Paginator
                    currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    first={first} rows={pageSize} totalRecords={(props.avrs||{totalElements: 0}).totalElements} rowsPerPageOptions={[1, 10, 25, 50]}
                    onPageChange={onPageChange}
                />
            </div>
        );
    };

    return (
        <div className="p-fluid p-grid p-p-lg-4">
            <Toast ref={toast}/>
            {(props.slqUser || {}).realmId === 'slq_jur' &&
                <Button onClick={() => showActivationDialog()} label="Активация ЮЛ"/>
            }

            <Dialog header="Тарифный план пользователя" visible={showCreateDialog} style={{ width: '50vw' }} footer={renderCreateFooter()}
                    onHide={() => onCreateHide()}>
                <form ref={createTaskForm} id="createForm" onSubmit={(e) => sendCreateForm(e)}>
                    <div className="card">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6 p-md-12">
                                <label htmlFor="firstname6" className="p-pb-2">Процент</label>
                                <InputNumber name="percent" value={percent} onValueChange={(e) => setPercent(e.value)}
                                             suffix={" %"} max={100}/>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6 p-md-12">
                                <label htmlFor="limit" className="p-pb-2">Лимит</label>
                                <InputNumber name="limit" value={limit} onValueChange={(e) => setLimit(e.value)}
                                             suffix={" тенге"} id="limit"/>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6 p-md-12">
                                <label htmlFor="firstname6" className="p-pb-2">Тип скидки</label>
                                 <Dropdown name="discountType" optionLabel="label" optionValue="value" value={discountType} options={discountTypeSelectItems}
                                           onChange={(e) => setDiscountType(e.value)}/>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6 p-md-12">
                                <label htmlFor="firstname6" className="p-pb-2">Тип оплаты</label>
                                <Dropdown name="paymentType" optionLabel="label" optionValue="value" value={paymentType} options={paymentTypeSelectItems}
                                          onChange={(e) => setPaymentType(e.value)}/>
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog
                header="Назначить следующий  статус АВР"
                visible={showChangeStatusDialog}
                style={{ width: '50vw' }}
                footer={renderFooter}
                onHide={() => setShowChangeStatusDialog(false)}
            >
                <p>Статус: {(props.statuses?.find((stat) => stat?.id === selectedAvrStatus?.statusId) || {name: ''})?.name}</p>
            </Dialog>

            <div className="leftSide">
                <h1 className="infoCardTitle">Данные пользователя</h1>
                <div className="infoCard">
                    <div className="tableRow">
                        <label htmlFor="username" className="infoCardLabel">Логин(Номер телефона)</label>
                        <div className="infoCardValue">
                            <input id="username" readOnly defaultValue={(props.slqUser||{}).username}/>
                        </div>
                    </div>
                    <div className="tableRow">
                        <label htmlFor="id" className="infoCardLabel">GUID</label>
                        <div className="infoCardValue">
                            <input id="id" readOnly defaultValue={(props.slqUser||{}).id}/>
                        </div>
                    </div>
                    <div className="tableRow">
                        <label htmlFor="lastName" className="infoCardLabel">Фамилия</label>
                        <div className="infoCardValue">
                            <input id="lastName" readOnly defaultValue={(props.slqUser||{}).lastName}/>
                        </div>
                    </div>
                    <div className="tableRow">
                        <label htmlFor="firstName" className="infoCardLabel">Имя</label>
                        <div className="infoCardValue">
                            <input id="firstName" readOnly defaultValue={(props.slqUser||{}).firstName}/>
                        </div>
                    </div>
                    <div className="tableRow">
                        <label htmlFor="email" className="infoCardLabel">Email</label>
                        <div className="infoCardValue">
                            <input id="email" readOnly defaultValue={(props.slqUser||{}).email}/>
                        </div>
                    </div>
                    <Attributes attributes={(props.slqUser||{}).attributes}/>
                </div>
            </div>
            <div className="rightSide">
                <h1 className="infoCardTitle">Данные кошелька</h1>
                <div className="infoCard">
                    <div className="tableRow">
                        <label htmlFor="username" className="infoCardLabel">Баланс, ₸</label>
                        <div className="infoCardValue">
                            <input id="username" readOnly defaultValue={(props.wallet||{}).balance}/>
                        </div>
                    </div>
                    <div className="tableRow">
                        <label htmlFor="username" className="infoCardLabel">В блокировке, ₸</label>
                        <div className="infoCardValue">
                            <input id="username" readOnly defaultValue={(props.wallet||{}).reserved}/>
                        </div>
                    </div>
                    <div className="tableRow">
                        <label htmlFor="username" className="infoCardLabel">Доступно, ₸</label>
                        <div className="infoCardValue">
                            <input id="username" readOnly defaultValue={(props.wallet||{}).available}/>
                        </div>
                    </div>
                </div>
            </div>

            {props.slqUser?.realmId === 'slq_jur' ?
                <div style={{width: '100%'}}>
                    <Fieldset>
                        <form onSubmit={handleSearchAvrs}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-6 p-md-3 hiddenInput">
                                    <label htmlFor="avrNum">Номер АВР</label>
                                    <InputText
                                        id="avrNum" value={filters.avrNum}
                                        onChange={(e) => setFilters({...filters, avrNum: e.target.value})}
                                    />
                                </div>

                                <div className="p-field p-col-6 p-md-3">
                                    <label htmlFor="avrsStatus">Статус АВР</label>
                                    <Dropdown inputId="avrsStatus" value={filters.status ? filters.status : null}
                                        options={props.statuses}
                                        onChange={(e) => setFilters({...filters, status: e.value})}
                                        optionLabel="name"
                                        optionValue="id"
                                        showClear
                                    />
                                </div>
                            </div>

                            <br/>
                            <div className="p-grid p-justify-end">
                                <div className="p-mr-2">
                                    <Button type="submit" label="Найти" autoFocus/>
                                </div>
                                <div className="p-mr-2">
                                    <Button className="p-button-warning" label="Сбросить" onClick={() => setFilters(initialFilters)}/>
                                </div>
                            </div>
                        </form>
                    </Fieldset>
                </div> : null
            }

            {props.slqUser?.realmId === 'slq_jur' && props.avrs?.content ? <Table /> : null}

            {props.slqUser?.realmId === 'slq_jur' ? null :
                <div className="card">
                    <DataTable value={(props.walletHistory||{data: []}).data}
                        className="p-datatable-customers" dataKey="id" rowHover
                        emptyMessage="Данные не найдены" scrollable>
                        <Column headerStyle={{ width: '200px' }} columnKey="info" field="info" header="Событие" sortable/>
                        <Column headerStyle={{ width: '200px' }} columnKey="createdDate" field="createdDate" header="Дата" sortable/>
                        <Column headerStyle={{ width: '200px' }} columnKey="amount" field="amount" header="Сумма, ₸" body={amountBodyTemplate} sortable/>
                    </DataTable>
                    <Paginator currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            first={first} rows={pageSize} totalRecords={(props.walletHistory||{totalItems: 0}).totalItems} rowsPerPageOptions={[1, 10, 25, 50, 'все']}
                            onPageChange={onPageChange}/>
                </div>
            }
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(SLQUser);
